import React from 'react'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../../../store/GlobalStore'

export default function Repay({ inputValues, SetInputValues, setClassValidate, classValidate }) {
	return (
		<>
			{inputValues.processor === 'repay' && (
				<div className="d-flex flex-column">
					<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
						{/* <div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.repay.clientId}
								inputDisabled={false}
								labelValue="Client ID"
								name="clientId"
								placeholder="Value"
								value={inputValues.clientId}
								onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, clientId: inputValues.clientId ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, clientId: values }) | setClassValidate({ ...classValidate, repay: { ...classValidate.repay, clientId: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>

							<RowContainer
								inputType="mask"
								classNameInput={classValidate.repay.clientSecretKey}
								inputDisabled={false}
								labelValue="Client Secret Key"
								name="clientSecretKey"
								placeholder="Value"
								value={inputValues.clientSecretKey}
								onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, clientSecretKey: inputValues.clientSecretKey ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, clientSecretKey: values }) | setClassValidate({ ...classValidate, repay: { ...classValidate.repay, clientSecretKey: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div> */}
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.repay.customerId}
								inputDisabled={false}
								labelValue="Customer ID"
								name="customerId"
								placeholder="Value"
								value={inputValues.customerId}
								onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, customerId: inputValues.customerId ? '' : 'is-invalid' } })}
								setValue={(values) => {
									const newValue = GlobalStore.removeSpaces(values)
									SetInputValues({ ...inputValues, customerId: newValue })
									setClassValidate({ ...classValidate, repay: { ...classValidate.repay, customerId: newValue ? '' : 'is-invalid' } })
								}}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>

						{/* <div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.repay.accountIdCredentials}
								inputDisabled={false}
								labelValue="Account ID"
								name="accountIdCredentials"
								placeholder="Value"
								value={inputValues.accountIdCredentials}
								onBlur={() => setClassValidate({ ...classValidate, repay: { ...classValidate.repay, accountIdCredentials: inputValues.accountIdCredentials ? '' : 'is-invalid' } })}
								setValue={(values) => SetInputValues({ ...inputValues, accountIdCredentials: values }) | setClassValidate({ ...classValidate, repay: { ...classValidate.repay, accountIdCredentials: values ? '' : 'is-invalid' } })}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div> */}
					</div>
				</div>
			)}
		</>
	)
}
