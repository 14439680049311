import { useContext } from 'react'
import { scroller } from 'react-scroll'
import commandContext from '../../context/commandCenter_context'

export default function Record({ setInputDisabled, showModal, setShowModal, setShowAlertFees, setClassValidate, loadService, element }) {
	const { services, deleteService, loadPaypointServiceName, updateService, toastMessage } = useContext(commandContext)
	const createBtn = (moneyType, serviceType) => {
		const newBtn = []
		services[moneyType][serviceType].map((service, indexService) =>
			service.Gateways.map((gateway, indexGateways) =>
				newBtn.push({
					header: 'PROCESSOR INFORMATION #' + (indexGateways + 1),
					useEdit: true,
					functionEdit: () =>
						setInputDisabled(indexGateways) |
						scroller.scrollTo(element + indexGateways, {
							offset: indexGateways === 0 ? -370 : -350
						}),
					useInactivate: true,
					functionInactivate: () =>
						setShowModal({
							...showModal,
							message: gateway.Connector.Enabled ? 'This action will inactivate the selected Service Gateway.' : 'This action will activate the selected Service Gateway.',
							message2: 'Do you want to continue?',
							showMessage: true,
							blueBtn: true,
							btnCaption: gateway.Connector.Enabled ? 'INACTIVATE GATEWAY' : 'ACTIVATE GATEWAY',
							function: () => activeInactiveServ(moneyType, serviceType, indexService, indexGateways, gateway.Connector.Enabled)
						}),
					state: gateway.Connector.Enabled,
					btnDelete: true,
					functionDelete: () => setShowModal({ ...showModal, message: 'This action will delete the selected Service Gateway.', message2: 'Do you want to continue?', showMessage: true, blueBtn: false, btnCaption: 'DELETE SERVICE', function: () => deleteServ(moneyType, serviceType, indexService, indexGateways) })
				})
			)
		)
		return newBtn
	}
	const deleteServ = async (moneyType, serviceType, indexService, indexGateways, allServices = false) => {
		await deleteService(serviceType, moneyType, indexService, indexGateways, allServices)
	}
	const activeInactiveServ = async (moneyType, serviceType, indexService, indexGateways, state, stateService = '') => {
		stateService === '' ? await updateService('updateStateGateway', serviceType, moneyType, indexService, indexGateways, state ? 'Service Gateway deactivated successfully!' : 'Service Gateway activated successfully!', '', 0) : await updateService('updateService', serviceType, moneyType, indexService, indexGateways, stateService ? 'Service deactivated successfully!' : 'Service activated successfully!', '', 0, !stateService)
	}
	const editService = async (serviceGroup, serviceName, indexService, indexGateways) => {
		let valid = false
		const deposits = { state: false, id: '' },
			//chargeBacks = { state: false, id: '' },
			withdrawalsFees = { state: false, id: '' }
		const validate = {
			gateway: '',
			processor: '',
			provider: '',
			nmi: {
				apiKey: '',
				gatewayId: ''
			},
			gp: {
				merchantId: '',
				transactionAccountName: '',
				transactionAccountId: '',
				fundingAccountName: '',
				fundingAccountId: ''
			},
			checkcommerce: {
				username: '',
				password: '',
				merchantNumber: ''
			},
			repay: {
				//clientId: '',
				//clientSecretKey: '',
				customerId: ''
				//accountIdCredentials: ''
			},
			BankData: [],
			check: [],
			purpose: []
		}

		if (!services[serviceGroup][serviceName][indexService].Gateways[indexGateways].GatewayName.toString().trim()) {
			validate.gateway = 'is-invalid'
			valid = true
		}

		if (!services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.Name.toString().trim()) {
			validate.processor = 'is-invalid'
			valid = true
		}

		if (!services[serviceGroup][serviceName][indexService].ServiceProviderName.toString().trim()) {
			validate.provider = 'is-invalid'
			valid = true
		}

		if (services[serviceGroup][serviceName][indexService].Gateways.length > 1) {
			if (!services[serviceGroup][serviceName][indexService].Gateways[indexGateways].AccountId.toString().trim()) {
				validate.accountId = 'is-invalid'
				valid = true
			}
		} else {
			validate.accountId = ''
		}

		if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.Name === 'gp') {
			if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.configuration.enableCreditCapability && parseFloat(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.configuration.creditLimit) === 0) {
				valid = true
			}
		}

		if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.Name !== 'dummy') {
			for (let field in validate[services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.Name]) {
				if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.configuration.credentials[field]) {
					if (!services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.configuration.credentials[field].toString().trim()) {
						validate[services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.Name][field] = 'is-invalid'
						valid = true
					}
				} else {
					validate[services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Connector.Name][field] = 'is-invalid'
					valid = true
				}
			}

			if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData !== null) {
				for (let i = 0; i < services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData.length; i++) {
					if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id === 0) {
						validate.BankData.push({ id: services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id, type: 'empty' })
						valid = true
					}
				}

				if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData.length > 1) {
					for (let i = 0; i < services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData.length; i++) {
						for (let k = 0; k < services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData.length; k++) {
							if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[k].id !== '0' && services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[k].id === services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id && i !== k) {
								validate.BankData.push({ id: services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id, type: 'duplicate' })
								validate.BankData.push({ id: services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[k].id, type: 'duplicate' })
								valid = true
							}
						}
					}
				}

				for (let i = 0; i < services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData.length; i++) {
					if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 3) {
						validate.purpose.push({ id: i, type: 'is-invalid' })
						valid = true
					}
				}

				for (let i = 0; i < services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData.length; i++) {
					if ((services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 0 && !deposits.state && !deposits.id) || (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && !deposits.state && !deposits.id)) {
						deposits.state = true
						deposits.id = services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id
					} else if (
						(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 0 && deposits.state && deposits.id !== services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id) ||
						(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && deposits.state && deposits.id !== services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id)
					) {
						validate.check.push({ id: i, type: 'deposits' })
						valid = true
					}

					/* if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].chargeBacks && !chargeBacks.state && !chargeBacks.id) {
					chargeBacks.state = true
					chargeBacks.id = services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id
				} else if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].chargeBacks && chargeBacks.state && chargeBacks.id !== services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id) {
					validate.check.push({ id: services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id, type: 'chargeBacks' })
					valid = true
				} */

					if ((services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 1 && !withdrawalsFees.state && !withdrawalsFees.id) || (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && !withdrawalsFees.state && !withdrawalsFees.id)) {
						withdrawalsFees.state = true
						withdrawalsFees.id = services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id
					} else if (
						(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 1 && withdrawalsFees.state && withdrawalsFees.id !== services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id) ||
						(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].bankAccountFunction === 2 && withdrawalsFees.state && withdrawalsFees.id !== services[serviceGroup][serviceName][indexService].Gateways[indexGateways].BankData[i].id)
					) {
						validate.check.push({ id: i, type: 'withdrawalsFees' })
						valid = true
					}
				}
			} else {
				setShowAlertFees(true)
			}

			if (serviceName !== 'cash' && serviceName !== 'check') {
				if (!withdrawalsFees.state) {
					setShowAlertFees(true)
					valid = true
				} else {
					setShowAlertFees(false)
				}
			}
		}

		if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Tiers !== null) {
			for (let i = 0; i < services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Tiers.length; i++) {
				if (parseInt(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Tiers[i].highPayRange) <= parseInt(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Tiers[i].lowPayRange)) {
					valid = true
				}

				if (services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Tiers[i].payorPrice !== null && parseInt(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Tiers[i].payorPrice.priceMaximum) < parseInt(services[serviceGroup][serviceName][indexService].Gateways[indexGateways].Tiers[i].payorPrice.priceMinimum)) {
					valid = true
				}
			}
		}
		setClassValidate(validate)
		if (valid) {
			toastMessage('You must fill in the required fields', false)
			return
		}

		const res = await updateService('updateServiceName', serviceName, serviceGroup, indexService, indexGateways, 'Service saved successfully!')
		if (!res) return
		await loadPaypointServiceName('', '', serviceGroup, serviceName)
		setInputDisabled('')
	}
	const clearService = (serviceName) => {
		setClassValidate({
			gateway: '',
			processor: '',
			provider: '',
			accountId: '',
			nmi: {
				apiKey: '',
				gatewayId: ''
			},
			gp: {
				merchantId: '',
				transactionAccountName: '',
				transactionAccountId: '',
				fundingAccountName: '',
				fundingAccountId: ''
			},
			checkcommerce: {
				username: '',
				password: '',
				merchantNumber: ''
			},
			repay: {
				//clientId: '',
				//clientSecretKey: '',
				customerId: ''
				//accountIdCredentials: ''
			},
			BankData: [],
			check: [],
			purpose: []
		})
		if (serviceName !== 'cash' && serviceName !== 'check') setShowAlertFees(false)
		setInputDisabled('')
		loadService()
	}

	return { createBtn, deleteServ, activeInactiveServ, editService, clearService }
}
