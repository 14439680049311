import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReportPaginator } from '../../components/ReportPaginator';
import { BoardingLinks } from '../../components/BoardingLinks';
import { Layout } from '../../components/Layout'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Modal, Button } from "react-bootstrap";
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { PayabliStorageManager } from '../../api/localStorageManager'
import {BiX} from '@react-icons/all-files/bi/BiX';
import {BiEnvelope} from '@react-icons/all-files/bi/BiEnvelope';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';
import { BiDotsVerticalRounded } from 'react-icons/bi';

@inject('reports','store','global','boarding')
@observer
class Links extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            orgId:0,
            saveEmailModalIsOpen: false,
            cancelLinkModalIsOpen: false,
            linkIdToModify: null,
            appIdToModify: null,
            flagItemAllColumn: true,
            appemail: null
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.openCancelLinkModal = this.openCancelLinkModal.bind(this);
        this.closeCancelLinkModal = this.closeCancelLinkModal.bind(this);
        this.cancelLinkAction = this.cancelLinkAction.bind(this);
        this.closeSaveEmailModal = this.closeSaveEmailModal.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId;
        this.setState({ head: "", cancelLinkModalIsOpen: false, orgId: orgId });

        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                OrgParentName   : { label: 'Organization', class: '', display: true },
                ReferenceName   : { label: 'Reference', class: '', display: true },
                TemplateName    : { label: 'Template', class: '', display: true },
                TemplateCode    : { label: 'Template Code', class: '', display: false },
                AcceptRegister  : { label: 'Accept Register?', class: '', display: true },
                AcceptOauth     : { label: 'Accept Authentication?', class: '', display: true },
                LastUpdated     : { label: 'Last Updated', class: '', display: true },
            }
        )
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    viewRecordDetails(link, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            window.open( link, '_blank');
        }
    }
   
    handleShowColumn(event) {
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    clearFilters() {
        this.setState({ head: "", dateStartDate: null, dateEndtDate: null }, function () {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    showHideFilters() {
        this.props.global.setFilterRightPanelOpen(true);
    }

    filter(type, value) {
        this.props.global.setLoading(true);
        this.props.reports.filterOrg(type, value, 'boardinglinks').then(res => {
            this.props.global.setLoading(false);
        })
            .catch(error => {
                this.props.global.setLoading(false);
            });
    }

    clearFilter(e, filter, filterType) {
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter, "");
    }

    getReportFromApi() {
        this.props.global.setLoading(true);
        this.props.reports.getReportOrgFromApi('boardinglinks').then(res => {
            this.props.global.setLoading(false);
        })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
    }

    handleEmailChange(event) {
        this.setState({ appemail: event.target.value });
    }

    saveToComplete() {
        if (this.state.appemail === null) {
            this.setState({ saveEmailModalIsOpen: true });
        }
        else {
            this.props.global.setLoading(true);
            this.props.boarding.sendLink(this.state.appIdToModify, this.state.appemail).then(res => {
                this.props.global.setLoading(false);
                toast.success("Application Link sent!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
            }).catch(error => {
                this.props.global.setLoading(false);
                toast.error('Oops! Something went wrong sending email!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    cancelLink(idLink) {
        this.setState({ linkIdToModify: idLink });
        this.openCancelLinkModal();
    }

    sendLinkToApp(idApp) {
        this.setState({ appIdToModify: idApp, saveEmailModalIsOpen: true });
    }

    sendActionEmailModal() {
        if (this.state.appemail === null) {
            this.setState({ saveEmailModalIsOpen: false });
            return;
        }
        let validators = this.props.global.validators;
        if (validators.stringValidator('email', this.state.appemail)) {
            this.setState({ saveEmailModalIsOpen: false, appemail: null });
        }
        else this.setState({ saveEmailModalIsOpen: false }, this.saveToComplete);
    }

    closeSaveEmailModal() {
        this.setState({ saveEmailModalIsOpen: false, appemail: "" });
    }

    openCancelLinkModal() {
        this.setState({ cancelLinkModalIsOpen: true });
    }

    closeCancelLinkModal() {
        this.setState({ cancelLinkModalIsOpen: false });
    }

    cancelLinkAction() {
        let linkIdToDelete = this.state.linkIdToModify;
        if (linkIdToDelete) {
            this.props.global.setLoading(true);
            this.props.reports.deleteTemplate(linkIdToDelete)
                .then(result => {
                    this.setState({ cancelLinkModalIsOpen: false });
                    this.props.global.setLoading(false);
                    toast.success("Boarding link deleted successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }
    }

    render() {
        return (
            <Layout {...this.props}>
                <Modal style={{ textAlign: "center" }} show={this.state.cancelLinkModalIsOpen} onHide={this.closeCancelLinkModal} size="sm" centered>
                    <Modal.Body>
                        <BiX className="icon-modal" />
                        <h5 data-qaid="deleteBoardingLinkPopUp">Delete</h5>
                        <p className="small">Are you sure you want to delete this link?</p>
                            <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeCancelLinkModal()} data-qaid="cancelBoardingLinkButton">
                            Cancel
                            </Button>
                            &nbsp;&nbsp;
                        <Button className="btn" variant="danger" onClick={(e) => this.cancelLinkAction()}>
                            Delete
                        </Button>
                    </Modal.Body>
                </Modal>
                {this.state.saveEmailModalIsOpen && <Modal style={{ textAlign: "center" }} show={this.state.saveEmailModalIsOpen} onHide={this.closeSaveEmailModal} size="sm" centered>
                    <Modal.Body>
                        <BiEnvelope className="icon-modal" />
                        <h5 data-qaid="enterEmailBoardingLinkPopUp">Enter an Email</h5>
                        <p className="small">Please enter an email address below where you will send a link to this application for it completion.</p>
                        <label className="small mb-2"><b>Email address</b></label>
                        <input className="form-control mb-3" type="email" placeholder="name@example.com" value={this.state.appemail} onChange={this.handleEmailChange} data-qaid="emailBoardingLinkInput"/>
                        <button className="btn btn-primary" onClick={(e) => this.sendActionEmailModal()} data-qaid="sendBoardingLinkButton">
                            Send
                        </button>
                    </Modal.Body>
                </Modal>
}
            <div>
                <ParentFilterPanel report={'boardinglinks'} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <BoardingLinks selected="links" />
                </div>
                </TopBar>
                <div className="mt-body4">
                    <MainBar
                        reportName="Boarding Links"
                        dataQAName="BoardingLinks"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        rootTemplate={false}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={false}
                        searchBar={false}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 
                    <div className="report-container">
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    {
                                        this.props.reports.getHeaders.map((record, i) => (
                                            (record[1].display && <th key={i} scope="col" className={record[1].class}>{record[1].label}</th>)
                                        ))
                                    }
                                    <th className='text-center sticky-row' scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.reports.records.map((record, i) => (
                                    <tr key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails(`${process.env.REACT_APP_URL_BOARDING}/boarding/app/${record.ReferenceName}`,e)} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} data-qaid={"boardingLinksRow-"+(i)}>
                                        { (this.props.reports.headers.OrgParentName && this.props.reports.headers.OrgParentName.display) &&
                                            <td>{this.props.global.capitalizeFirstLetterOfEachWord(record.OrgParentName)}</td>
                                        }
                                        { (this.props.reports.headers.ReferenceName && this.props.reports.headers.ReferenceName.display) &&
                                            <td>{record.ReferenceName ? record.ReferenceName : '-'}</td>
                                        }
                                        { (this.props.reports.headers.TemplateName && this.props.reports.headers.TemplateName.display) &&
                                            <td>{record.TemplateName ? record.TemplateName : '-'}</td>
                                        }
                                        { (this.props.reports.headers.TemplateCode && this.props.reports.headers.TemplateCode.display) &&
                                            <td>{record.TemplateCode ? record.TemplateCode : '-'}</td>
                                        }
                                        { (this.props.reports.headers.AcceptRegister && this.props.reports.headers.AcceptRegister.display) &&
                                            <td>{record.AcceptRegister === 0?"Yes":"No"}</td>
                                        }
                                        { (this.props.reports.headers.AcceptOauth && this.props.reports.headers.AcceptOauth.display) &&
                                            <td>{record.AcceptOauth === 0 ? "Yes" : "No"}</td>
                                        }
                                        { (this.props.reports.headers.LastUpdated && this.props.reports.headers.LastUpdated.display) &&
                                            <td>{this.props.global.stringDateFormatV3(record.LastUpdated)}</td>
                                        }
                                        <td className='text-center sticky-row'>
                                            <DropdownButton
                                                menuAlign="right"
                                                title={<BiDotsVerticalRounded/>}
                                                data-qaid={"actionBoardingLinks-"+(record.TemplateName)}
                                                id="actionsMenuButton"
                                                size="sm"
                                                variant="default"
                                            >
                                                <Dropdown.Item rel="noreferrer" href={`${process.env.REACT_APP_URL_BOARDING}/boarding/app/${record.ReferenceName}`} target="_blank" data-qaid="openBoardingLinksLink">Open</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => this.sendLinkToApp(record.Id)} data-qaid="sendEmailBoardingLinksLink">Send Link to Email</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item style={{ color: "rgb(192, 0, 0)" }} onClick={(e) => this.cancelLink(record.Id)} data-qaid="deleteBoardingLinksLink">Delete</Dropdown.Item>
                                            </DropdownButton>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>                
                        {this.props.reports.records.length < 1 &&
                            <RecordsNotFound message="No boarding links just yet" description={<span>Upon creating an application template,<br/> boarding links are also created and will appear here.</span>}/>
                        }
                    </div>
                    <ReportPaginator report="boardinglinks" mode={this.state.orgId} option={1} />
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { Links };
