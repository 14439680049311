import React from "react";
import { inject, observer } from "mobx-react";

@inject("vTerminal", "applepay")
@observer
class ApplePayButtonStyle extends React.Component {
  constructor(props) {
    super(props);
    this.handleTextChangeSelect = this.handleTextChangeSelect.bind(this);
  }

  handleTextChangeSelect(e) {
    const selectedValue = e.target.value;
    this.props.vTerminal.setApplePayButton({ buttonStyle: selectedValue });
  }

  render() {
    return (
      <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-select form-control"
              id="appleButtonStyle"
              name="appleButtonStyle"
              onChange={this.handleTextChangeSelect}
              value={
                this.props.vTerminal.paymentPage.paymentMethods.settings.applePay
                  .buttonStyle
              }
              data-qaid="appleButtonStyleInput"
            >
              {this.props.applepay.applePayButtonStyles.map((style) => (
                <option
                  key={style.value}
                  value={style.value}
                  title={style.description}
                >
                  {style.text}
                </option>
              ))}
            </select>
            <label htmlFor="appleButtonStyle">Style</label>
          </div>
        </div>
      </div>
    );
  }
}

export { ApplePayButtonStyle };
