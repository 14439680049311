import React from 'react'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../../../store/GlobalStore'

export default function Nmi({ inputValues, SetInputValues, setClassValidate, classValidate }) {
	return (
		<>
			{inputValues.processor === 'nmi' && (
				<div className="d-flex flex-column">
					<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
						<RowContainer
							inputType="mask"
							classNameInput={classValidate.nmi.apiKey}
							inputDisabled={false}
							labelValue="API Key"
							name="apiKey"
							placeholder="Value"
							value={inputValues.apiKey}
							onBlur={() => setClassValidate({ ...classValidate, nmi: { ...classValidate.nmi, apiKey: inputValues.apiKey ? '' : 'is-invalid' } })}
							setValue={(values) => {
								const newValue = GlobalStore.removeSpaces(values)
								SetInputValues({ ...inputValues, apiKey: newValue })
								setClassValidate({ ...classValidate, nmi: { ...classValidate.nmi, apiKey: newValue ? '' : 'is-invalid' } })
							}}
							mask={GlobalStore.maskValidator('text')}
							maxLength={50}
							oneRow={true}
						/>

						<RowContainer
							inputType="mask"
							classNameInput={classValidate.nmi.gatewayId}
							inputDisabled={false}
							labelValue="Gateway ID"
							name="gatewayId"
							placeholder="Value"
							value={inputValues.gatewayId}
							onBlur={() => setClassValidate({ ...classValidate, nmi: { ...classValidate.nmi, gatewayId: inputValues.gatewayId ? '' : 'is-invalid' } })}
							setValue={(values) => {
								const newValue = GlobalStore.removeSpaces(values)
								SetInputValues({ ...inputValues, gatewayId: newValue })
								setClassValidate({ ...classValidate, nmi: { ...classValidate.nmi, gatewayId: newValue ? '' : 'is-invalid' } })
							}}
							mask={GlobalStore.maskValidator('text')}
							maxLength={50}
							oneRow={true}
						/>

						<RowContainer
							inputType="mask"
							inputDisabled={false}
							labelValue="Service Fee Key (Opt)"
							name="serviceFeeKey"
							placeholder="Value"
							value={inputValues.serviceFeeKey}
							setValue={(values) => SetInputValues({ ...inputValues, serviceFeeKey: GlobalStore.removeSpaces(values) })}
							mask={GlobalStore.maskValidator('text')}
							maxLength={50}
							oneRow={true}
							TooltipCaption={
								<>
									This credential is <strong>optional</strong> as it is used to associate a second service fee credential to enable dual authentication for service fee payments through Legacy gateway.
								</>
							}
						/>

						<RowContainer
							inputType="mask"
							inputDisabled={false}
							labelValue="Webhook ID (Opt)"
							name="webhookKey"
							placeholder="Value"
							value={inputValues.webhookKey}
							setValue={(values) => SetInputValues({ ...inputValues, webhookKey: GlobalStore.removeSpaces(values) })}
							mask={GlobalStore.maskValidator('text')}
							maxLength={50}
							oneRow={true}
							// TooltipCaption={
							// 	<>
							// 		This credential is <strong>optional</strong> as it is used to associate a second service fee credential to enable dual authentication for service fee payments through Legacy gateway.
							// 	</>
							// }
						/>
					</div>
				</div>
			)}
		</>
	)
}
