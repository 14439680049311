import React, { useState, useContext } from 'react'
import styles from '../../../../assets/css/styleCommandCenter.module.css'
import { RiCloseLine } from 'react-icons/ri'
import { Modal } from 'react-bootstrap'
import commandContext from '../../context/commandCenter_context'
import Processor from './Processor'
import WalletView from './WalletView'
import Credential from './Credential/Credential'
import Tiers from './Credential/Tiers'

export default function AddGateway({ showAddService, closeModal, sectionActive, serviceTypeActive, addBankAccount }) {
	const { createService } = useContext(commandContext)
	const [showAlertFees, setShowAlertFees] = useState(false)
	const [inputValues, SetInputValues] = useState({
		ServiceGroup: sectionActive ? sectionActive.toLowerCase() : '',
		ServiceName: serviceTypeActive ? serviceTypeActive.toLowerCase() : '',
		gateway: '',
		processor: '',
		provider: 'Payabli',
		accountId: '',
		apiKey: '',
		gatewayId: '',
		serviceFeeKey: '',
		webhookKey: '',
		merchantId: '',
		tokenizationAccountName: '',
		tokenizationAccountId: '',
		transactionAccountName: '',
		transactionAccountId: '',
		fundingAccountName: '',
		fundingAccountId: '',
		username: '',
		password: '',
		merchantNumber: '',
		//clientId: '',
		//clientSecretKey: '',
		customerId: '',
		//accountIdCredentials: '',
		BankData: [{ id: 'B1', idSelect: '0', accountNumber: '', bankAccountHolderName: '', bankAccountHolderType: '', bankName: '', routingAccount: '', typeAccount: '', bankAccountFunctionResp: 3, bankAccountFunction: 3 }],
		Tiers: [
			{
				mode: 2,
				highPayRange: 0,
				lowPayRange: 0,
				absorbDifference: false,
				greaterValueAllowed: false,
				allowOverride: false,
				priceFloat: 0,
				priceFix: 0,
				priceMinimum: 0,
				priceMaximum: 0
			}
		]
	})
	const [classValidate, setClassValidate] = useState({
		gateway: '',
		processor: '',
		provider: '',
		accountId: '',
		nmi: {
			apiKey: '',
			gatewayId: ''
		},
		gp: {
			merchantId: '',
			transactionAccountName: '',
			transactionAccountId: '',
			fundingAccountName: '',
			fundingAccountId: ''
		},
		checkcommerce: {
			username: '',
			password: '',
			merchantNumber: ''
		},
		repay: {
			//clientId: '',
			//clientSecretKey: '',
			customerId: ''
			//accountIdCredentials: ''
		},
		BankData: [],
		check: [],
		purpose: []
	})
	const [showSection, setShowSection] = useState({
		serviceMoneyIn: sectionActive === 'moneyIn' ? true : false,
		serviceMoneyOut: sectionActive === 'moneyOut' ? true : false,
		processor: sectionActive && serviceTypeActive !== 'wallet' ? true : false,
		credential: false,
		walletView: sectionActive && serviceTypeActive === 'wallet' ? true : false,
		tiers: false
	})
	const [service, setService] = useState({
		cardIn: sectionActive === 'moneyIn' && serviceTypeActive === 'card' ? true : false,
		achIn: sectionActive === 'moneyIn' && serviceTypeActive === 'ach' ? true : false,
		checkIn: sectionActive === 'moneyIn' && serviceTypeActive === 'check' ? true : false,
		cashIn: sectionActive === 'moneyIn' && serviceTypeActive === 'cash' ? true : false,
		cloudIn: sectionActive === 'moneyIn' && serviceTypeActive === 'cloud' ? true : false,
		walletIn: sectionActive === 'moneyIn' && serviceTypeActive === 'wallet' ? true : false,
		managedOut: sectionActive === 'moneyOut' && serviceTypeActive === 'managed' ? true : false,
		achOut: sectionActive === 'moneyOut' && serviceTypeActive === 'ach' ? true : false,
		checkOut: sectionActive === 'moneyOut' && serviceTypeActive === 'check' ? true : false,
		virtualOut: sectionActive === 'moneyOut' && serviceTypeActive === 'vcard' ? true : false,
		pushOut: sectionActive === 'moneyOut' && serviceTypeActive === 'pushToCard' ? true : false,
		sectionCounter: 1
	})
	const createServiceData = async () => {
		let valid = false
		const deposits = { state: false, id: '' },
			//chargeBacks = { state: false, id: '' },
			withdrawalsFees = { state: false, id: '' }
		const validate = {
			gateway: '',
			processor: '',
			provider: '',
			nmi: {
				apiKey: '',
				gatewayId: ''
			},
			gp: {
				merchantId: '',
				transactionAccountName: '',
				transactionAccountId: '',
				fundingAccountName: '',
				fundingAccountId: ''
			},
			checkcommerce: {
				username: '',
				password: '',
				merchantNumber: ''
			},
			repay: {
				//clientId: '',
				//clientSecretKey: '',
				customerId: ''
				//accountIdCredentials: ''
			},
			BankData: [],
			check: [],
			purpose: []
		}

		if (!inputValues.gateway.toString().trim()) {
			validate.gateway = 'is-invalid'
			valid = true
		}

		if (!inputValues.processor.toString().trim()) {
			validate.processor = 'is-invalid'
			valid = true
		}

		if (!inputValues.provider.toString().trim()) {
			validate.provider = 'is-invalid'
			valid = true
		}

		if (!inputValues.accountId.toString().trim()) {
			validate.accountId = 'is-invalid'
			valid = true
		}

		if (inputValues.processor !== 'dummy') {
			for (let field in validate[inputValues.processor]) {
				if (!inputValues[field].toString().trim()) {
					validate[inputValues.processor][field] = 'is-invalid'
					valid = true
				}
			}

			for (let i = 0; i < inputValues.BankData.length; i++) {
				if (inputValues.BankData[i].idSelect.toString().trim() === '0') {
					validate.BankData.push({ id: inputValues.BankData[i].id, type: 'empty' })
					valid = true
				}
			}

			if (inputValues.BankData.length > 1) {
				for (let i = 0; i < inputValues.BankData.length; i++) {
					for (let k = 0; k < inputValues.BankData.length; k++) {
						if (inputValues.BankData[k].idSelect.toString().trim() !== '0' && inputValues.BankData[k].idSelect === inputValues.BankData[i].idSelect && i !== k) {
							validate.BankData.push({ id: inputValues.BankData[i].id, type: 'duplicate' })
							validate.BankData.push({ id: inputValues.BankData[k].id, type: 'duplicate' })
							valid = true
						}
					}
				}
			}

			for (let i = 0; i < inputValues.BankData.length; i++) {
				if (inputValues.BankData[i].bankAccountFunction === 3) {
					validate.purpose.push({ id: inputValues.BankData[i].id, type: 'is-invalid' })
					valid = true
				}
			}

			for (let i = 0; i < inputValues.BankData.length; i++) {
				if ((inputValues.BankData[i].bankAccountFunction === 0 && !deposits.state && !deposits.id) || (inputValues.BankData[i].bankAccountFunction === 2 && !deposits.state && !deposits.id)) {
					deposits.state = true
					deposits.id = inputValues.BankData[i].id
				} else if ((inputValues.BankData[i].bankAccountFunction === 0 && deposits.state && deposits.id !== inputValues.BankData[i].id) || (inputValues.BankData[i].bankAccountFunction === 2 && deposits.state && deposits.id !== inputValues.BankData[i].id)) {
					validate.check.push({ id: inputValues.BankData[i].id, type: 'deposits' })
					valid = true
				}

				/* if (inputValues.BankData[i].chargeBacks && !chargeBacks.state && !chargeBacks.id) {
					chargeBacks.state = true
					chargeBacks.id = inputValues.BankData[i].id
				} else if (inputValues.BankData[i].chargeBacks && chargeBacks.state && chargeBacks.id !== inputValues.BankData[i].id) {
					validate.check.push({ id: inputValues.BankData[i].id, type: 'chargeBacks' })
					valid = true
				} */

				if ((inputValues.BankData[i].bankAccountFunction === 1 && !withdrawalsFees.state && !withdrawalsFees.id) || (inputValues.BankData[i].bankAccountFunction === 2 && !withdrawalsFees.state && !withdrawalsFees.id)) {
					withdrawalsFees.state = true
					withdrawalsFees.id = inputValues.BankData[i].id
				} else if ((inputValues.BankData[i].bankAccountFunction === 1 && withdrawalsFees.state && withdrawalsFees.id !== inputValues.BankData[i].id) || (inputValues.BankData[i].bankAccountFunction === 2 && withdrawalsFees.state && withdrawalsFees.id !== inputValues.BankData[i].id)) {
					validate.check.push({ id: inputValues.BankData[i].id, type: 'withdrawalsFees' })
					valid = true
				}
			}

			if (!withdrawalsFees.state) {
				setShowAlertFees(true)
				valid = true
			} else {
				setShowAlertFees(false)
			}
		}

		for (let i = 0; i < inputValues.Tiers.length; i++) {
			if (parseInt(inputValues.Tiers[i].highPayRange) <= parseInt(inputValues.Tiers[i].lowPayRange)) {
				valid = true
			}
			if (parseInt(inputValues.Tiers[i].priceMaximum) < parseInt(inputValues.Tiers[i].priceMinimum)) {
				valid = true
			}
		}

		setClassValidate(validate)
		if (valid) return

		const res = await createService(inputValues, 'Service Gateway created successfully', false)
		if (!res) return
		closeModal()
	}

	return (
		<Modal
			show={showAddService}
			onHide={() => closeModal()}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header>
				<div className={'d-flex justify-content-between w-100 px-4 ' + styles['user-select']}>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className={'w-100 fs-5 ' + styles['label-form'] + ' ' + styles['ft-s-16'] + ' ' + styles['color-blue']}
						style={{ fontWeight: '600', lineHeight: '24px' }}>
						Add New Processing Service Gateway
					</Modal.Title>
					<RiCloseLine
						onClick={() => closeModal()}
						className={styles['icon24']}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className={'d-flex flex-column gap-3 ' + styles['user-select']}>
					<Processor
						setShowSection={setShowSection}
						showSection={showSection}
						setService={setService}
						sectionCounter={2}
						inputValues={inputValues}
						SetInputValues={SetInputValues}
						service={service}
						setClassValidate={setClassValidate}
						classValidate={classValidate}
						useProvider={false}
					/>
					<WalletView showSection={showSection} />
					<Credential
						showSection={showSection}
						SetInputValues={SetInputValues}
						inputValues={inputValues}
						classValidate={classValidate}
						showAlertFees={showAlertFees}
						setShowAlertFees={setShowAlertFees}
						setClassValidate={setClassValidate}
						service={service}
						addBankAccount={addBankAccount}
					/>
					<Tiers
						inputValues={inputValues}
						SetInputValues={SetInputValues}
						showSection={showSection}
						service={service}
					/>
					<div className="d-flex flex-row align-self-center justify-content-between w-100">
						<label>{`STEP ${service.sectionCounter} OF 2`}</label>
						<div className="d-flex flex-row align-self-end gap-4">
							<button
								onClick={() => closeModal()}
								className={styles['btn'] + ' ' + styles['btn-font-weight']}
								style={{ color: '#000000' }}>
								CANCEL
							</button>
							<button
								disabled={service.sectionCounter === 2 ? false : true}
								onClick={() => createServiceData()}
								className={`${styles['btn']} ${service.sectionCounter === 2 ? styles['btn-blue'] : styles['btn-grey'] + ' ' + styles['btn-font-weight'] + ' ' + styles['color-disabled']}`}>
								ADD GATEWAY
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
