import React from "react";
import { inject, observer } from "mobx-react";

@inject("vTerminal", "applepay")
@observer
class ApplePayButtonType extends React.Component {
  constructor(props) {
    super(props);
    this.handleTextChangeSelect = this.handleTextChangeSelect.bind(this);
  }

  handleTextChangeSelect(e) {
    const selectedValue = e.target.value;
    this.props.vTerminal.setApplePayButton({ buttonType: selectedValue });
  }

  render() {
    return (
      <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-select form-control"
              id="appleButtonType"
              name="appleButtonType"
              onChange={this.handleTextChangeSelect}
              value={
                this.props.vTerminal.paymentPage.paymentMethods.settings.applePay
                  .buttonType
              }
              data-qaid="appleButtonTypeInput"
            >
              {this.props.applepay.applePayButtonTypes.map((type) => (
                <option
                  key={type.value}
                  value={type.value}
                  title={type.description}
                >
                  {type.text}
                </option>
              ))}
            </select>
            <label htmlFor="appleButtonType">Type</label>
          </div>
        </div>
      </div>
    );
  }
}

export { ApplePayButtonType };
