import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiCopy, BiShow, BiLinkExternal, BiXCircle } from 'react-icons/bi';
import { FileViewer } from './FileViewer';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import dayjs from 'dayjs';

@inject('global', 'reports', 'bill')
@observer
class QuickViewPayouts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vCardData: {
				cardNumber: null,
				cardExp: null,
				cardCvv: null,
				method: 'vcard'
			}
		};
	}

	componentWillUnmount() {
		this.resetVcardData();
	}

	resetVcardData() {
		const vcardData = {
			cardNumber: null,
			cardCvv: null,
			cardExp: null,
			method: 'vcard'
		};
		this.setState({ vCardData: vcardData });
	}

	getCardMethodByNumber(cardNumber) {
		if (cardNumber) {
			if (cardNumber.startsWith('4')) {
				return 'visa';
			} else if (cardNumber.startsWith('5')) {
				return 'mastercard';
			} else {
				return 'vcard';
			}
		} else {
			return 'vcard';
		}
	}

	formatCardNumber(cardNumber) {
		if (cardNumber) {
			return cardNumber.replace(/\d{4}(?=.)/g, '$& ');
		} else {
			return '•••• •••• •••• ••••';
		}
	}

	formatCardExp(cardExp) {
		return cardExp ? dayjs(cardExp).format('MM/YY') : '••/••';
	}

	getUnmaskedVcard(transId) {
		if (!transId) {
			return;
		}

		this.props.global.setLoading(true);
		this.props.bill
			.getUnmaskedVcard(transId)
			.then((response) => {
				this.props.global.setLoading(false);
				if (response) {
					const vCardData = {
						cardNumber: this.formatCardNumber(response.cardNumber),
						cardExp: this.formatCardExp(response.expirationDate),
						cardCvv: response.cvc,
						method: this.getCardMethodByNumber(response.cardNumber)
					};
					this.setState({ vCardData: vCardData });
				} else {
					const errorMessage = 'Something is Wrong!';
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					});
				}
			})
			.catch((error) => {
				toast.error(error?.message || 'Something is Wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				});
			});
	}

	copyToClipboard(e) {
		try {
			e.preventDefault();
			const vCardData = this.state.vCardData;
			const copyText = vCardData.cardNumber?.replace(/\s/g, '');
			if (!copyText) {
				toast.error('No data to copy!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				});
				return;
			}
			navigator.clipboard.writeText(copyText);
			toast.success('Copied to clipboard!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-success-container'
			});
		} catch (e) {
			const errorMessage = 'Failed to copy!';
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			});
		}
	}

	render() {
		const recordDetail = this.props.reports.getRecordDetails;
		return (
			<>
				<h5
					className='header mb-3'
					data-qaid='transactionMoneyOutDetails'>
					Transaction Details
				</h5>
				<div className='small mb-5'>
					<div className='row'>
						<div className='col-4'>
							<label className='header'>
								Date & Time {' (' + this.props.global.getTimeZone('v1') + ')'}
							</label>
						</div>
						<div className='col-8'>
							{recordDetail ? this.props.global.stringDateFormat(recordDetail.CreatedAt) : '-'}-
							{recordDetail ? this.props.global.stringTimeFormat(recordDetail.CreatedAt) : '-'}
						</div>
					</div>
					<div className='row'>
						<div className='col-4'>
							<label className='header'>
								Last Updated {' (' + this.props.global.getTimeZone('v1') + ')'}
							</label>
						</div>
						<div className='col-8'>
							{recordDetail ? this.props.global.stringDateFormat(recordDetail.LastUpdated) : '-'}-
							{recordDetail ? this.props.global.stringTimeFormat(recordDetail.LastUpdated) : '-'}
						</div>
					</div>

					<div className='row'>
						<div className='col-4'>
							<label className='header'>Type</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.PaymentMethod
								? this.props.global.capitalizeFirstLetter(recordDetail.PaymentMethod)
								: '-'}
						</div>
					</div>

					<div className='row'>
						<div className='col-4'>
							<label className='header'>Status</label>
						</div>
						<div className='col-8'>
							{this.props.global.getMoneyOutTransStatus(recordDetail?.Status)}
						</div>
					</div>

					<div className='row mb-4'>
						{recordDetail && recordDetail.Comments && (
							<div className='col-12'>
								<label className='header'>Notes:</label>
								<p>{recordDetail.Comments}</p>
							</div>
						)}

						{recordDetail && recordDetail.Bills && (
							<div className='col-12 mb-3 mt-3'>
								{recordDetail.Bills.map((record, i) => (
									<div
										className='row mb-1'
										key={i}>
										<div className='col-6'>
											<b>Bill Number {i + 1}</b>
										</div>
										<div className='col-6 text-right'>
											<a
												href={`/${this.props.global.getURLEntry()}/report/bills/bill/${
													record.invoiceNumber
												}`}>
												{record.invoiceNumber}
											</a>
										</div>
									</div>
								))}
							</div>
						)}

						<div className='col-12'>
							<div className='row mb-1'>
								<div className='col-4'>
									<b>Amount</b>
								</div>
								<div className='col-8 text-right'>
									{recordDetail && recordDetail.NetAmount
										? this.props.global.numberWithCommasNegative(recordDetail.NetAmount.toFixed(2))
										: '-'}
								</div>
							</div>

							<div className='row mb-1'>
								<div className='col-4'>
									<b>Fee</b>
								</div>
								<div className='col-8 text-right'>
									{recordDetail && recordDetail.FeeAmount
										? this.props.global.numberWithCommasNegative(recordDetail.FeeAmount.toFixed(2))
										: '$0.00'}
								</div>
							</div>

							<div className='row mb-1'>
								<div className='col-4'>
									<b>Total</b>
								</div>
								<div className='col-8 text-right'>
									<b>
										{recordDetail && recordDetail.TotalAmount
											? this.props.global.numberWithCommasNegative(
													recordDetail.TotalAmount.toFixed(2)
											  )
											: '-'}
									</b>
								</div>
							</div>
						</div>
					</div>
					<h5 className='header mb-3'>Payment Information</h5>
					{recordDetail?.PaymentMethod?.toLowerCase() === 'vcard' ? (
						<div
							className='card-v2 mb-3'
							style={{ padding: '3em' }}>
							<div className='right-panel-card mb-4'>
								{this.props.global.getPaymethodImg(this.state.vCardData.method || 'vcard', '45px')}
							</div>
							<div className='row mb-2'>
								<div className='col-8'>
									<label className='grey'>Card Number</label>
									<div
										className='d-flex flex-row text-nowrap '
										style={{ alignItems: 'center' }}>
										<h5
											className='mb-0'
											style={{ fontWeight: 500 }}>
											{this.state.vCardData.cardNumber
												? this.state.vCardData.cardNumber
												: '•••• •••• •••• ••••'}
										</h5>
										{recordDetail.Status === 1 && (
											<button
												className='btn btn-link py-0 ml-1 px-0'
												style={{ color: '#334155', boxShadow: 'none' }}
												onClick={
													this.state.vCardData.cardNumber
														? (e) => this.copyToClipboard(e)
														: () => this.getUnmaskedVcard(recordDetail.PaymentId)
												}>
												{this.state.vCardData.cardNumber ? (
													<BiCopy style={{ fontSize: '24px' }} />
												) : (
													<BiShow style={{ fontSize: '24px' }} />
												)}
											</button>
										)}
									</div>
								</div>
								<div className='col-4 text-center'>
									<label className='grey'>Expires on</label>
									<h5 style={{ fontWeight: 500 }}>
										{this.state.vCardData.cardExp ? this.state.vCardData.cardExp : '••/••'}
									</h5>
								</div>
							</div>
							<div className='row'>
								<div className='col-8'>
									<label className='grey'>Paypoint Legal Name</label>
									<h6 style={{ fontWeight: 500 }}>{`${recordDetail?.PaypointLegalname || ''}`}</h6>
								</div>
								<div className='col-4 text-center'>
									<label className='grey'>CVV</label>
									<h6 style={{ fontWeight: 500 }}>
										{this.state.vCardData.cardCvv ? this.state.vCardData.cardCvv : '•••'}
									</h6>
								</div>
							</div>
						</div>
					) : (
						<div
							className='card-v2 mb-3'
							style={{ padding: '3em' }}>
							<div className='right-panel-card mb-4'>
								{recordDetail?.PaymentMethod?.toLowerCase() === 'ach' ||
								recordDetail?.PaymentMethod?.toLowerCase() === 'check' ? (
									<span style={{ verticalAlign: 'bottom' }}>
										{this.props.global.getGlobalImg(
											recordDetail?.PaymentMethod?.toLowerCase(),
											'45px'
										)}
									</span>
								) : (
									<span style={{ fontSize: '20px', paddingTop: '4px' }}>
										{this.props.global.capitalizeFirstLetter(recordDetail?.PaymentMethod)}
									</span>
								)}
							</div>

							<div className='row mb-2'>
								<div className='col-7'>
									<label className='grey'>Payment Status</label>
									<h5 style={{ fontWeight: 500, fontSize: '14px' }}>
										{recordDetail && recordDetail.PaymentStatus ? recordDetail.PaymentStatus : '-'}
									</h5>
								</div>

								<div className='col-5'>
									<label className='grey'>Date</label>
									<h5 style={{ fontWeight: 500, fontSize: '14px' }}>
										{recordDetail
											? this.props.global.stringDateFormat(recordDetail.CreatedAt)
											: '-'}
									</h5>
								</div>
							</div>

							<div className='row'>
								<div className='col-12'>
									<label className='grey'>Paypoint Name</label>
									<h6 style={{ fontWeight: 500, fontSize: '15px' }}>
										{recordDetail && recordDetail.PaypointDbaname
											? recordDetail.PaypointDbaname
											: '-'}
									</h6>
								</div>
							</div>
						</div>
					)}

					<div className='text-center mb-4'>
						<div
							className='btn-group'
							role='group'>
							{recordDetail && (recordDetail.Status === 1 || recordDetail.Status === 11) && (
								<button
									onClick={(e) => this.props.cancelPayment(recordDetail.PaymentId)}
									type='button'
									className='btn btn-outline-primary'>
									<BiXCircle /> Cancel Payment
								</button>
							)}
							<button
								onClick={() =>
									this.props.viewVendor(
										`/${this.props.global.getURLEntry()}/vendor/${
											recordDetail.Vendor.VendorId || ''
										}`
									)
								}
								type='button'
								className='btn btn-outline-primary'>
								<BiLinkExternal /> View Vendor
							</button>
							{recordDetail && recordDetail.CheckData && (
								<FileViewer
									fileData={recordDetail.CheckData}
									fileType={recordDetail.CheckData.ftype}
									isQuickView={true}
								/>
							)}
						</div>
					</div>

					<h5 className='header mb-3'>Vendor Information</h5>
					<div className='row mb-4'>
						<div className='col-4'>
							<label className='header'>Vendor Name</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.Name1
								? this.props.global.capitalizeFirstLetterOfEachWord(
										recordDetail.Vendor.Name1 + ' ' + recordDetail.Vendor.Name2
								  )
								: '-'}
						</div>
						<div className='col-4'>
							<label className='header'>Vendor ID</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.VendorId
								? recordDetail.Vendor.VendorId
								: '-'}
						</div>
						<div className='col-4'>
							<label className='header'>Vendor Number</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.VendorNumber
								? recordDetail.Vendor.VendorNumber
								: '-'}
						</div>
						<div className='col-4'>
							<label className='header'>Email</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.Email
								? recordDetail.Vendor.Email
								: '-'}
						</div>
						<div className='col-4'>
							<label className='header'>Phone</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.Phone
								? this.props.global.phoneNumberFormat(recordDetail.Vendor.Phone)
								: '-'}
						</div>
						<div className='col-4'>
							<label className='header'>Billing Address</label>
						</div>
						<div className='col-8'>
							{(() => {
								const addressParts = [
									recordDetail?.Vendor?.Address1,
									recordDetail?.Vendor?.Address2,
									recordDetail?.Vendor?.City,
									recordDetail?.Vendor?.State,
									recordDetail?.Vendor?.Zip,
									recordDetail?.Vendor?.Country
								].filter(Boolean);
								return addressParts.length > 0 ? addressParts.join(' ') : '-';
							})()}
						</div>
						<div className='col-4'>
							<label className='header'>Remit Address</label>
						</div>
						<div className='col-8'>
							{(() => {
								const addressParts = [
									recordDetail?.Vendor?.remitAddress1,
									recordDetail?.Vendor?.remitAddress2,
									recordDetail?.Vendor?.remitCity,
									recordDetail?.Vendor?.remitState,
									recordDetail?.Vendor?.remitZip,
									recordDetail?.Vendor?.remitCountry
								].filter(Boolean);
								return addressParts.length > 0 ? addressParts.join(' ') : '-';
							})()}
						</div>
					</div>

					<h5 className='header mb-3'>Processing Information</h5>
					<div className='row mb-4'>
						<div className='col-4'>
							<label className='header'>Payment ID</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.PaymentId !== null ? recordDetail.PaymentId : '-'}
						</div>

						<div className='col-4'>
							<label className='header'>Payment Status</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.PaymentStatus
								? this.props.global.capitalizeFirstLetter(recordDetail.PaymentStatus)
								: '-'}
						</div>

						<div className='col-4'>
							<label className='header'>Source</label>
						</div>
						<div className='col-8'>
							{recordDetail && recordDetail.Source
								? this.props.global.capitalizeFirstLetter(recordDetail.Source)
								: '-'}
						</div>
					</div>

					{recordDetail?.Events?.length > 0 && (
						<>
							<h5 className='header mb-3'>Transaction Event History</h5>
							<div className='timeLineRecordContainer'>
								{recordDetail.Events.map((record, i) => (
									<div
										key={i}
										className='timeLineRecordItem'>
										<div className='timeLineRecord'>&nbsp;</div>
										<div className='timeLineRecordText'>
											<b>{record.TransEvent}</b>
											<br />
											{this.props.global.stringDateFormat(record.EventTime)}{' '}
											<span className='grey'>
												{this.props.global.stringTimeFormat(record.EventTime)}{' '}
												{'(' + this.props.global.getTimeZone('v1') + ')'}
											</span>
										</div>
										<br />
									</div>
								))}
							</div>
						</>
					)}
				</div>
				<ToastContainer transition={Bounce} />
			</>
		);
	}
}

export { QuickViewPayouts };
