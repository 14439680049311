import React from "react";
import { inject, observer } from "mobx-react";
import { TopBarSingle } from "../../components/TopBarSingle";
import { MainTopBarMenu } from "../../components/MainTopBarMenu";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Layout } from "../../components/Layout";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { PayabliStorageManager } from "../../api/localStorageManager";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiTrendingUp } from "@react-icons/all-files/bi/BiTrendingUp";
import ElementTrending from "../../components/Dashboard/ElementTrending";

@inject("global", "reports")
@observer
class Dashboard extends React.Component {
  orgId = 0;

  constructor(props) {
    super(props);
    this.state = {
      dataMTD: null,
      dataMTDReceivable: null,
      inChartValue: "",
      accountTransactions: [],
      accountTransactionsMo: [],
      recentBatch: [],
      recentBatchMo: [],
    };
    this.moneyInDiffLastPeriod = this.moneyInDiffLastPeriod.bind(this);
    this.dataChart = this.dataChart.bind(this);
    this.showError = this.showError.bind(this);
    this.normalizeDateString = this.normalizeDateString.bind(this);
  }

  showError(error) {
    let errorMessage =
      error.response && error.response.data.responseText
        ? error.response.data.responseText
        : "Something is Wrong!";
    toast.error(errorMessage, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "toast-error-container",
    });
  }

  moneyInDiffLastPeriod(p1, p2) {
    if (p1 === 0) return 0;
    return (((p2 - p1) / p1) * 100).toFixed(2);
  }

  dataChart(array, labelField, dataField) {
    let data = [];
    let thisObj = this;
    data.labels = [];
    data.data = [];
    array.forEach(function (item) {
      let normalizedString = thisObj.normalizeDateString(item[labelField]);
      data.labels.push(normalizedString);
      data.data.push(item[dataField]);
    });
    return data;
  }

  normalizeDateString(string) {
    let newString = String(string)
      .replace(/ /g, "")
      .replace("{Year=", "")
      .replace(",Month=", "-")
      .replace("}", "");
    return newString;
  }

  componentDidMount() {
    this.props.global.protect(this.props.history);
    this.props.global.setLoading(true);
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    this.orgId =
      encryptStorage &&
      encryptStorage.getItem(this.props.global.getURLEntry() + "_pEntry")
        ? encryptStorage.getItem(this.props.global.getURLEntry() + "_pEntry")
            .orgId
        : null;

    let userPermissions =
      encryptStorage &&
      encryptStorage.getItem("pUser") &&
      encryptStorage.getItem("pUser").permissions
        ? encryptStorage.getItem("pUser").permissions
        : [];

    if (encryptStorage && this.orgId && userPermissions !== []) {
      axios
        .get(
          process.env.REACT_APP_URL_API +
            "Statistic/basic/m12/m/0/" +
            this.orgId,
          {
            headers: {
              requestToken: encryptStorage.getItem("pToken"),
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.setState({ dataMTD: res.data });
          }
        })
        .catch((error) => {
          this.showError(error);
          this.props.global.setLoading(false);
        });

      axios
        .get(
          process.env.REACT_APP_URL_API +
            "Statistic/Subscriptions/all/0/" +
            this.orgId,
          {
            headers: {
              requestToken: encryptStorage.getItem("pToken"),
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.setState({ dataMTDReceivable: res.data });
          }
          this.props.global.setLoading(false);
        })
        .catch((error) => {
          this.showError(error);
          this.props.global.setLoading(false);
        });

      if (userPermissions.indexOf("Transaction") !== -1) {
        this.props.global.setLoading(true);
        axios
          .get(
            process.env.REACT_APP_URL_API +
              "Query/transactions/org/" +
              this.orgId +
              "?limitRecord=5&fromRecord=0",
            {
              headers: {
                requestToken: encryptStorage.getItem("pToken"),
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.setState({ accountTransactions: res.data.Records });
            }
            this.props.global.setLoading(false);
          })
          .catch((error) => {
            this.showError(error);
            this.props.global.setLoading(false);
          });
      }

      if (userPermissions.indexOf("MoneyOut Actions") !== -1) {
        this.props.global.setLoading(true);
        axios
          .get(
            process.env.REACT_APP_URL_API +
              "Query/payouts/org/" +
              this.orgId +
              "?limitRecord=5&fromRecord=0",
            {
              headers: {
                requestToken: encryptStorage.getItem("pToken"),
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.setState({ accountTransactionsMo: res.data.Records });
            }
            this.props.global.setLoading(false);
          })
          .catch((error) => {
            this.showError(error);
            this.props.global.setLoading(false);
          });
      }

      if (userPermissions.indexOf("Batch") !== -1) {
        this.props.global.setLoading(true);
        axios
          .get(
            process.env.REACT_APP_URL_API +
              "Query/batches/org/" +
              this.orgId +
              "?limitRecord=5&fromRecord=0",
            {
              headers: {
                requestToken: encryptStorage.getItem("pToken"),
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.setState({ recentBatch: res.data.Records });
            }
            this.props.global.setLoading(false);
          })
          .catch((error) => {
            this.showError(error);
            this.props.global.setLoading(false);
          });
      }

      if (userPermissions.indexOf("MoneyOut Actions") !== -1) {
        this.props.global.setLoading(true);
        axios
          .get(
            process.env.REACT_APP_URL_API +
              "Query/batchesOut/org/" +
              this.orgId +
              "?limitRecord=5&fromRecord=0",
            {
              headers: {
                requestToken: encryptStorage.getItem("pToken"),
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.setState({ recentBatchMo: res.data.Records });
            }
            this.props.global.setLoading(false);
          })
          .catch((error) => {
            this.showError(error);
            this.props.global.setLoading(false);
          });
      }
    }
  }

  render() {
    let reactObj = this;
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let userPermissions =
      encryptStorage &&
      encryptStorage.getItem("pUser") &&
      encryptStorage.getItem("pUser").permissions
        ? encryptStorage.getItem("pUser").permissions
        : [];

    let DataChartMoneyIn = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "inTransactionsVolume")
      : [];

    let payInData = {
      labels: DataChartMoneyIn.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyIn.data,
          fill: true,
          borderColor: "rgb(36, 193, 143)",
          backgroundColor: "rgba(36, 193, 143, 0.05)",
          tension: 0.5,
          type: "line",
          pointBorderColor: "rgba(36, 193, 143, 0.5)",
        },
      ],
    };

    let DataChartMoneyInSub = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "inSubscriptionsPaidVolume")
      : [];
    let payInSubData = {
      labels: DataChartMoneyInSub.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyInSub.data,
          fill: true,
          borderColor: "rgb(254, 95, 154)",
          backgroundColor: "rgba(254, 95, 154, 0.05)",
          tension: 0.5,
          type: "line",
          pointBorderColor: "rgba(254, 95, 154, 0.5)",
        },
      ],
    };

    let DataChartMoneyOut = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "outTransactionsVolume")
      : [];
    let payOutData = {
      labels: DataChartMoneyOut.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyOut.data,
          fill: true,
          borderColor: "rgb(84, 89, 234)",
          backgroundColor: "rgba(84, 89, 234, 0.05)",
          tension: 0.5,
          type: "line",
          pointBorderColor: "rgba(84, 89, 234, 0.5)",
        },
      ],
    };

    let DataChartMoneyOutSub = this.state.dataMTD
      ? this.dataChart(
          this.state.dataMTD,
          "statX",
          "outSubscriptionsPaidVolume"
        )
      : [];
    let payOutSubData = {
      labels: DataChartMoneyOutSub.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyOutSub.data,
          fill: true,
          borderColor: "rgb(248, 128, 38)",
          backgroundColor: "rgba(248, 128, 38, 0.05)",
          tension: 0.5,
          type: "line",
          pointBorderColor: "rgba(248, 128, 38, 0.5)",
        },
      ],
    };

    let optionsHorizontalLineChart = {
      aspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: "index",
          intersect: false,
          backgroundColor: "#ffffff",
          position: "average",
          titleColor: "#5A5F7D",
          titleFontSize: 13,
          titleSpacing: 15,
          bodyColor: "#868EAE",
          borderColor: "#F1F2F6",
          borderWidth: 2,
          padding: 15,
          z: 999999,
          custom(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            title() {
              //return ``;
            },
            label(t) {
              return `${reactObj.props.global.stringDateFormatV2(
                t.label
              )}: ${reactObj.props.global.numberWithCommas(parseFloat(t.raw))}`;
            },
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: "#FFA726",
                borderColor: "transparent",
              };
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            borderColor: "rgb(192,201,214)",
          },
        },
        y: {
          display: false,
        },
      },
    };

    let pointBackgroundColor = [];
    if (this.state.dataMTD) {
      this.state.dataMTD.forEach(function (item) {
        pointBackgroundColor.push("transparent");
      });
      if (pointBackgroundColor.length) {
        pointBackgroundColor[pointBackgroundColor.length - 1] = "#FFA726";
      }
    }

    let DataChartMoneyInTransCount = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "inTransactions")
      : [];
    let payInTransCount = {
      labels: DataChartMoneyInTransCount.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyInTransCount.data,
          borderColor: "#c0c8d5",
          pointRadius: 6,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: "#FFA726",
          lineTension: 0,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: "#FFA726",
          pointHoverBorderColor: "#FFA726",
          borderWidth: 2,
        },
      ],
    };

    let DataChartMoneyOutTransCount = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "outTransactions")
      : [];
    let payOutTransCount = {
      labels: DataChartMoneyOutTransCount.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyOutTransCount.data,
          borderColor: "#c0c8d5",
          pointRadius: 6,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: "#FFA726",
          lineTension: 0,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: "#FFA726",
          pointHoverBorderColor: "#FFA726",
          borderWidth: 2,
        },
      ],
    };

    let DataChartMoneyInSubscriptions = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "inSubscriptionsPaid")
      : [];
    let payInSubscriptions = {
      labels: DataChartMoneyInSubscriptions.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyInSubscriptions.data,
          borderColor: "#c0c8d5",
          pointRadius: 6,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: "#FFA726",
          lineTension: 0,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: "#FFA726",
          pointHoverBorderColor: "#FFA726",
          borderWidth: 2,
        },
      ],
    };

    let DataChartMoneyOutSubscriptions = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "outSubscriptionsPaid")
      : [];
    let payOutSubscriptions = {
      labels: DataChartMoneyOutSubscriptions.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyOutSubscriptions.data,
          borderColor: "#c0c8d5",
          pointRadius: 6,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: "#FFA726",
          lineTension: 0,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: "#FFA726",
          pointHoverBorderColor: "#FFA726",
          borderWidth: 2,
        },
      ],
    };

    let DataChartMoneyInCustomers = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "inNewCustomers")
      : [];
    let payInCustomers = {
      labels: DataChartMoneyInCustomers.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyInCustomers.data,
          borderColor: "#c0c8d5",
          pointRadius: 6,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: "#FFA726",
          lineTension: 0,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: "#FFA726",
          pointHoverBorderColor: "#FFA726",
          borderWidth: 2,
        },
      ],
    };

    let DataChartMoneyOutVendors = this.state.dataMTD
      ? this.dataChart(this.state.dataMTD, "statX", "outNewCustomers")
      : [];
    let payOutVendors = {
      labels: DataChartMoneyOutVendors.labels,
      datasets: [
        {
          label: "$",
          data: DataChartMoneyOutVendors.data,
          borderColor: "#c0c8d5",
          pointRadius: 6,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: "#FFA726",
          lineTension: 0,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: "#FFA726",
          pointHoverBorderColor: "#FFA726",
          borderWidth: 2,
        },
      ],
    };

    let options = {
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: "index",
          intersect: false,
          backgroundColor: "#ffffff",
          position: "average",
          titleColor: "#5A5F7D",
          titleFontSize: 13,
          titleSpacing: 15,
          bodyColor: "#868EAE",
          borderColor: "#F1F2F6",
          borderWidth: 2,
          padding: 15,
          z: 999999,
          custom(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            title() {
              //return `Account Receivable`;
            },
            label(t) {
              return `${reactObj.props.global.stringDateFormatV2(
                t.label
              )}: $${reactObj.props.global.numberWithCommas(
                parseFloat(t.raw).toFixed(2)
              )}`;
            },
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: tooltipItem.dataset.borderColor,
                borderColor: "transparent",
              };
            },
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    };

    let AccountReceivableDataChartLine1 = this.state.dataMTDReceivable
      ? this.dataChart(this.state.dataMTDReceivable, "interval", "volume")
      : [];
    let AccountReceivableDataChartLine2 = this.state.dataMTDReceivable
      ? this.dataChart(this.state.dataMTDReceivable, "interval", "count")
      : [];
    let AccountReceivableData = {
      //labels:  AccountReceivableDataChartLine1.labels,
      labels: ["0-30", "30-60", "60-90", "91+"],
      datasets: [
        {
          label: "Volume",
          data: AccountReceivableDataChartLine1.data,
          borderColor: "#FA8B0C",
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#FA8B0C",
          pointBorderColor: "#fff",
          pointHoverBorderColor: "#fff",
          pointBorderWidth: 2,
          pointHoverBorderWidth: 3,
          pointHoverRadius: 5,
          z: 5,
          lineTension: 0.5,
        },
      ],
    };

    let AccountReceivableDataCount = {
      //labels:  AccountReceivableDataChartLine1.labels,
      labels: ["0-30", "30-60", "60-90", "91+"],
      datasets: [
        {
          label: "Count",
          data: AccountReceivableDataChartLine2.data,
          borderColor: "#FA8B0C",
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#FA8B0C",
          pointBorderColor: "#fff",
          pointHoverBorderColor: "#fff",
          pointBorderWidth: 2,
          pointHoverBorderWidth: 3,
          pointHoverRadius: 5,
          z: 5,
          lineTension: 0.5,
        },
      ],
    };

    let AccountReceivableOptions = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: "index",
          intersect: false,
          backgroundColor: "#ffffff",
          position: "average",
          titleColor: "#5A5F7D",
          titleFontSize: 13,
          titleSpacing: 15,
          bodyColor: "#868EAE",
          borderColor: "#F1F2F6",
          borderWidth: 2,
          padding: 15,
          z: 999999,
          custom(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            title() {
              //return `Account Receivable`;
            },
            label(t) {
              return `${t.label}: ${
                t.dataset.label
              } $${reactObj.props.global.numberWithCommas(
                parseFloat(t.raw).toFixed(2)
              )}`;
            },
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: tooltipItem.dataset.pointBackgroundColor,
                borderColor: "transparent",
              };
            },
          },
        },
      },
      elements: {
        point: {
          radius: 5,
          z: 5,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            color: "#e5e9f2",
            borderDash: [3, 3],
            zeroLineColor: "#e5e9f2",
            zeroLineWidth: 1,
            zeroLineBorderDash: [3, 3],
          },
        },
      },
    };

    let AccountReceivableOptionsCount = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: "index",
          intersect: false,
          backgroundColor: "#ffffff",
          position: "average",
          titleColor: "#5A5F7D",
          titleFontSize: 13,
          titleSpacing: 15,
          bodyColor: "#868EAE",
          borderColor: "#F1F2F6",
          borderWidth: 2,
          padding: 15,
          z: 999999,
          custom(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            title() {
              //return `Account Receivable`;
            },
            label(t) {
              return `${t.label}: ${t.dataset.label} ${t.raw}`;
            },
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: tooltipItem.dataset.pointBackgroundColor,
                borderColor: "transparent",
              };
            },
          },
        },
      },
      elements: {
        point: {
          radius: 5,
          z: 5,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            color: "#e5e9f2",
            borderDash: [3, 3],
            zeroLineColor: "#e5e9f2",
            zeroLineWidth: 1,
            zeroLineBorderDash: [3, 3],
          },
        },
      },
    };

    let AccountPayableData = {
      //labels:  AccountReceivableDataChartLine1.labels,
      labels: ["0-30", "30-60", "60-90", "91+"],
      datasets: [
        {
          label: "Volume",
          data: [0, 0, 0, 0],
          borderColor: "#2C99FF",
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#2C99FF",
          pointBorderColor: "#fff",
          pointHoverBorderColor: "#fff",
          pointBorderWidth: 2,
          pointHoverBorderWidth: 3,
          pointHoverRadius: 5,
          z: 5,
          lineTension: 0.5,
        },
      ],
    };

    let AccountPayableData1 = {
      //labels:  AccountReceivableDataChartLine1.labels,
      labels: ["0-30", "30-60", "60-90", "91+"],
      datasets: [
        {
          label: "Volume",
          data: [0, 0, 0, 0],
          borderColor: "#2C99FF",
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#2C99FF",
          pointBorderColor: "#fff",
          pointHoverBorderColor: "#fff",
          pointBorderWidth: 2,
          pointHoverBorderWidth: 3,
          pointHoverRadius: 5,
          z: 5,
          lineTension: 0.5,
        },
      ],
    };

    let moneyInPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2]
              .inTransactionsVolume,
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .inTransactionsVolume
          )
        : 0;
    let moneyInLastMonthVolume =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[
              this.state.dataMTD.length - 1
            ].inTransactionsVolume.toFixed(2)
          )
        : "0.00";

    let moneyInSubPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2]
              .inSubscriptionsPaidVolume,
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .inSubscriptionsPaidVolume
          )
        : 0;
    let moneyInLastMonthSubPaidVolume =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[
              this.state.dataMTD.length - 1
            ].inSubscriptionsPaidVolume.toFixed(2)
          )
        : "0.00";

    let moneyOutPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2]
              .outTransactionsVolume,
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .outTransactionsVolume
          )
        : 0;
    let moneyOutLastMonthVolume =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[
              this.state.dataMTD.length - 1
            ].outTransactionsVolume.toFixed(2)
          )
        : "0.00";

    let moneyOutSubPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2]
              .outSubscriptionsPaidVolume,
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .outSubscriptionsPaidVolume
          )
        : 0;
    let moneyOutLastMonthSubPaidVolume =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[
              this.state.dataMTD.length - 1
            ].outSubscriptionsPaidVolume.toFixed(2)
          )
        : "0.00";

    let moneyInTransCountPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2].inTransactions,
            this.state.dataMTD[this.state.dataMTD.length - 1].inTransactions
          )
        : 0;
    let moneyInTransCount =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[this.state.dataMTD.length - 1].inTransactions
          )
        : "0";

    let moneyOutTransCountPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2].outTransactions,
            this.state.dataMTD[this.state.dataMTD.length - 1].outTransactions
          )
        : 0;
    let moneyOutTransCount =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[this.state.dataMTD.length - 1].outTransactions
          )
        : "0";

    let moneyInSubscriptionsPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2]
              .inSubscriptionsPaid,
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .inSubscriptionsPaid
          )
        : 0;
    let moneyInSubscriptions =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .inSubscriptionsPaid
          )
        : "0";

    let moneyOutSubscriptionsPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2]
              .outSubscriptionsPaid,
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .outSubscriptionsPaid
          )
        : 0;
    let moneyOutSubscriptions =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[this.state.dataMTD.length - 1]
              .outSubscriptionsPaid
          )
        : "0";

    let moneyInCustomersPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2].inNewCustomers,
            this.state.dataMTD[this.state.dataMTD.length - 1].inNewCustomers
          )
        : 0;
    let moneyInCustomers =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[this.state.dataMTD.length - 1].inCustomers
          )
        : "0";

    let moneyOutVendorsPercent =
      this.state.dataMTD && this.state.dataMTD.length > 1
        ? this.moneyInDiffLastPeriod(
            this.state.dataMTD[this.state.dataMTD.length - 2].outNewCustomers,
            this.state.dataMTD[this.state.dataMTD.length - 1].outNewCustomers
          )
        : 0;
    let moneyOutVendors =
      this.state.dataMTD && this.state.dataMTD.length > 0
        ? this.props.global.numberWithCommas(
            this.state.dataMTD[this.state.dataMTD.length - 1].outCustomers
          )
        : "0";

    return (
      <Layout {...this.props} bgcolor="rgb(244,245,247)">
        <div>
          <TopBarSingle>
            <MainTopBarMenu />
          </TopBarSingle>

          <div className="mt-body5">
            <h5 className="mb-3" data-qaid="paymentDashboardPage">
              Payment Dashboard
            </h5>

            <div className="row">
              <div className="col-lg-6">
                <div className="card-v3 mb-4">
                  <div className="card-body">
                    <div
                      className="sub-header-line mb-4"
                      style={{ fontWeight: "400" }}
                    >
                      Money In
                    </div>
                    <div className="row">
                      <div className="col-md-6 chart-cont mb-4">
                        <p className="small mb-2">Total Money In $ MTD</p>
                        <h5>${moneyInLastMonthVolume}</h5>
                        <p className="small grey mb-3">
                          {<ElementTrending value={moneyInPercent} />}
                          <span className="small-small">
                            {this.state.dataMTD &&
                            this.state.dataMTD[this.state.dataMTD.length - 2]
                              ? `$${this.props.global.numberWithCommas(
                                  this.state.dataMTD[
                                    this.state.dataMTD.length - 2
                                  ].inTransactionsVolume.toFixed(2)
                                )} (prev)`
                              : ""}
                          </span>
                        </p>
                        <Line
                          data={payInData}
                          options={options}
                          height={window.innerWidth <= 575 ? 230 : 100}
                        />
                      </div>
                      <div className="col-md-6 chart-cont mb-4">
                        <p className="small mb-2">Total Scheduled $ MTD</p>
                        <h5>${moneyInLastMonthSubPaidVolume}</h5>
                        <p className="small grey mb-3">
                          {" "}
                          {<ElementTrending value={moneyInSubPercent} />}
                          <span className="small-small">
                            {this.state.dataMTD &&
                            this.state.dataMTD[this.state.dataMTD.length - 2]
                              ? `$${this.props.global.numberWithCommas(
                                  this.state.dataMTD[
                                    this.state.dataMTD.length - 2
                                  ].inSubscriptionsPaidVolume.toFixed(2)
                                )} (prev)`
                              : ""}
                          </span>
                        </p>
                        <Line
                          data={payInSubData}
                          options={options}
                          height={window.innerWidth <= 575 ? 230 : 100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-v3 mb-4">
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-4">
                        <p className="grey small-small mb-1">
                          Transaction Count
                        </p>
                        <h5>
                          {moneyInTransCount}{" "}
                          {<ElementTrending value={moneyInTransCountPercent} />}{" "}
                        </h5>
                      </div>
                      <div className="col-8" style={{ height: "60px" }}>
                        <Line
                          data={payInTransCount}
                          options={optionsHorizontalLineChart}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-4">
                        <p className="grey small-small mb-1">Autopays</p>
                        <h5>
                          {moneyInSubscriptions}{" "}
                          {
                            <ElementTrending
                              value={moneyInSubscriptionsPercent}
                            />
                          }{" "}
                        </h5>
                      </div>
                      <div className="col-8" style={{ height: "60px" }}>
                        <Line
                          data={payInSubscriptions}
                          options={optionsHorizontalLineChart}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <p className="grey small-small mb-1">Total Customers</p>
                        <h5>
                          {moneyInCustomers}{" "}
                          {<ElementTrending value={moneyInCustomersPercent} />}{" "}
                        </h5>
                      </div>
                      <div className="col-8" style={{ height: "60px" }}>
                        <Line
                          data={payInCustomers}
                          options={optionsHorizontalLineChart}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-v3 mb-4">
                  <div className="card-body chart-tab">
                    <div
                      className="sub-header-line mb-2"
                      style={{ fontWeight: 400 }}
                    >
                      Accounts Receivable
                      {userPermissions.indexOf("Subscriptions") !== -1 && (
                        <Link
                          to={
                            "/" +
                            this.props.global.getURLEntry() +
                            "/report/autopays/"
                          }
                          className="link-default float-end"
                        >
                          View Report
                        </Link>
                      )}
                    </div>

                    <Tab.Container defaultActiveKey="accountReceivableVolume">
                      <Nav
                        variant="tabs"
                        defaultActiveKey="accountReceivableVolume"
                        className="aa"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="accountReceivableVolume">
                            Volume &nbsp;
                            <span className="trending-up small-small">
                              {" "}
                              0-30 days
                            </span>{" "}
                            <br />
                            <h5 className="mt-2">
                              $
                              {this.state.dataMTDReceivable
                                ? this.props.global.numberWithCommas(
                                    this.state.dataMTDReceivable[0].volume.toFixed(
                                      2
                                    )
                                  )
                                : "0.00"}
                            </h5>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="accountReceivableCount">
                            Count &nbsp;
                            <span className="trending-up small-small">
                              {" "}
                              0-30 days
                            </span>{" "}
                            <br />
                            <h5 className="mt-2">
                              {this.state.dataMTDReceivable
                                ? this.state.dataMTDReceivable[0].count
                                : "0"}
                            </h5>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="accountReceivableVolume">
                          <div className="col-md-12 chart-cont mb-4 mt-4">
                            <Line
                              data={AccountReceivableData}
                              options={AccountReceivableOptions}
                              height={window.innerWidth <= 575 ? 230 : 100}
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="accountReceivableCount">
                          <div className="col-md-12 chart-cont mb-4 mt-4">
                            <Line
                              data={AccountReceivableDataCount}
                              options={AccountReceivableOptionsCount}
                              height={window.innerWidth <= 575 ? 230 : 100}
                            />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card-v3 mb-4">
                  <div className="card-body">
                    <div
                      className="sub-header-line mb-4"
                      style={{ fontWeight: "400" }}
                    >
                      Money Out
                    </div>
                    <div className="row">
                      <div className="col-md-6 chart-cont mb-4">
                        <p className="small mb-2">Total Money Out $ MTD</p>
                        <h5>${moneyOutLastMonthVolume}</h5>
                        <p className="small grey mb-3">
                          {" "}
                          {<ElementTrending value={moneyOutPercent} />}
                          <span className="small-small">
                            {this.state.dataMTD &&
                            this.state.dataMTD[this.state.dataMTD.length - 2]
                              ? `$${this.props.global.numberWithCommas(
                                  this.state.dataMTD[
                                    this.state.dataMTD.length - 2
                                  ].outTransactionsVolume.toFixed(2)
                                )} (prev)`
                              : ""}
                          </span>
                        </p>
                        <Line
                          data={payOutData}
                          options={options}
                          height={window.innerWidth <= 575 ? 230 : 100}
                        />
                      </div>
                      <div className="col-md-6 chart-cont mb-4">
                        <p className="small mb-2">Scheduled Bills to Pay</p>
                        <h5>${moneyOutLastMonthSubPaidVolume}</h5>
                        <p className="small grey mb-3">
                          {" "}
                          {<ElementTrending value={moneyOutSubPercent} />}
                          <span className="small-small">
                            {this.state.dataMTD &&
                            this.state.dataMTD[this.state.dataMTD.length - 2]
                              ? `$${this.props.global.numberWithCommas(
                                  this.state.dataMTD[
                                    this.state.dataMTD.length - 2
                                  ].outSubscriptionsPaidVolume.toFixed(2)
                                )} (prev)`
                              : ""}
                          </span>
                        </p>
                        <Line
                          data={payOutSubData}
                          options={options}
                          height={window.innerWidth <= 575 ? 230 : 100}
                        />
                      </div>
                      {/*<div className="col-md-12 col-lg-4 chart-cont">
                                        <Bar data={payOutTypeData} options={outOptionsDoughnut} />
                                    </div>*/}
                    </div>
                  </div>
                </div>

                <div className="card-v3 mb-4">
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-4">
                        <p className="grey small-small mb-1">
                          Bills Paid Count
                        </p>
                        <h5>
                          {moneyOutTransCount}{" "}
                          {
                            <ElementTrending
                              value={moneyOutTransCountPercent}
                            />
                          }{" "}
                        </h5>
                      </div>
                      <div className="col-8" style={{ height: "60px" }}>
                        <Line
                          data={payOutTransCount}
                          options={optionsHorizontalLineChart}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-4">
                        <p className="grey small-small mb-1">Recurring Bills</p>
                        <h5>
                          {moneyOutSubscriptions}{" "}
                          {
                            <ElementTrending
                              value={moneyOutSubscriptionsPercent}
                            />
                          }{" "}
                        </h5>
                      </div>
                      <div className="col-8" style={{ height: "60px" }}>
                        <Line
                          data={payOutSubscriptions}
                          options={optionsHorizontalLineChart}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <p className="grey small-small mb-1">Total Vendors</p>
                        <h5>
                          {moneyOutVendors}{" "}
                          {<ElementTrending value={moneyOutVendorsPercent} />}{" "}
                        </h5>
                      </div>
                      <div className="col-8" style={{ height: "60px" }}>
                        <Line
                          data={payOutVendors}
                          options={optionsHorizontalLineChart}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-v3 mb-4">
                  <div className="card-body chart-tab">
                    <div
                      className="sub-header-line mb-2"
                      style={{ fontWeight: 400 }}
                    >
                      Accounts Payable{" "}
                      <Link
                        to={
                          "/" +
                          this.props.global.getURLEntry() +
                          "/report/bills"
                        }
                        className="link-default float-end"
                      >
                        View Report
                      </Link>
                    </div>
                    <Tab.Container defaultActiveKey="accountPayableVolume">
                      <Nav
                        variant="tabs"
                        defaultActiveKey="accountPayableVolume"
                        className="aa"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="accountPayableVolume">
                            Volume &nbsp;
                            <span className="trending-up small-small">
                              {" "}
                              0-30 days
                            </span>{" "}
                            <br />
                            <h5 className="mt-2">$0.00</h5>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="accountPayableCount">
                            Count &nbsp;
                            <span className="trending-up small-small">
                              {" "}
                              0-30 days
                            </span>{" "}
                            <br />
                            <h5 className="mt-2">0</h5>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="accountPayableVolume">
                          <div className="col-md-12 chart-cont mb-4 mt-4">
                            <Line
                              data={AccountPayableData}
                              options={AccountReceivableOptions}
                              height={window.innerWidth <= 575 ? 230 : 100}
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="accountPayableCount">
                          <div className="col-md-12 chart-cont mb-4 mt-4">
                            <Line
                              data={AccountPayableData1}
                              options={AccountReceivableOptions}
                              height={window.innerWidth <= 575 ? 230 : 100}
                            />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-4">
                {userPermissions.indexOf("Batch") !== -1 && (
                  <div className="card-v3 mb-4">
                    <div className="card-body">
                      <div
                        className="sub-header-line mb-2"
                        style={{ fontWeight: "400" }}
                      >
                        Recent Batch Activity
                        {userPermissions.indexOf("Batch") !== -1 && (
                          <Link
                            to={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/report/batches/"
                            }
                            className="link-default float-end"
                          >
                            View Report
                          </Link>
                        )}
                      </div>
                      <table className="table small">
                        <tbody>
                          {this.state.recentBatch.map((record, i) => (
                            <tr key={i}>
                              <td>
                                <BiTrendingUp style={{ fontSize: "15px" }} />
                              </td>
                              <td>
                                <b>
                                  {this.props.global.stringDateFormat(
                                    record.BatchDate
                                  )}
                                </b>{" "}
                                <br />
                                <span className="grey small-small">date</span>
                              </td>
                              <td className="text-center">
                                {this.props.global.getBatchStatus(
                                  record.BatchStatus
                                )}{" "}
                                <br />
                                <span className="grey small-small">status</span>
                              </td>
                              <td className="text-right">
                                $
                                {this.props.global.numberWithCommas(
                                  record.BatchAmount.toFixed(2)
                                )}{" "}
                                <br />
                                <span className="grey small-small">amount</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {userPermissions.indexOf("Transaction") !== -1 && (
                  <div className="card-v3 mb-4">
                    <div className="card-body">
                      <div
                        className="sub-header-line mb-2"
                        style={{ fontWeight: "400" }}
                      >
                        Recent Transactions
                        {userPermissions.indexOf("Transaction") !== -1 && (
                          <Link
                            to={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/report/transactions/"
                            }
                            className="link-default float-end"
                          >
                            View Report
                          </Link>
                        )}
                      </div>
                      <table className="table small">
                        <tbody>
                          {this.state.accountTransactions.map((record, i) => (
                            <tr key={i}>
                              <td>
                                {this.props.global.maskedCardNumber(
                                  record.PaymentData.MaskedAccount,
                                  "v3"
                                )}{" "}
                                <br />
                                <span className="grey small-small">
                                  {this.props.global.stringDateFormat(
                                    record.TransactionTime
                                  )}
                                  ,{" "}
                                  {this.props.global.stringTimeFormat(
                                    record.TransactionTime
                                  )}{" "}
                                  {"(" +
                                    this.props.global.getTimeZone("v1") +
                                    ")"}
                                </span>
                              </td>
                              <td className="text-center">
                                $
                                {this.props.global.numberWithCommas(
                                  record.TotalAmount.toFixed(2)
                                )}{" "}
                                <br />
                                <span className="grey small-small">amount</span>
                              </td>
                              <td className="text-center">
                                {this.props.global.getPaymethodImg(
                                  record.PaymentData.AccountType
                                )}{" "}
                                <br />
                                <span className="grey small-small">
                                  {record.Method}
                                </span>
                              </td>
                              <td className="text-right">
                                {record.Payor && record.Payor.FirstName
                                  ? record.Payor.FirstName
                                  : ""}{" "}
                                {record.Payor && record.Payor.LastName
                                  ? record.Payor.LastName
                                  : ""}{" "}
                                <br />
                                <span className="grey small-small">pay</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-6 mb-4">
                {userPermissions.indexOf("Batch") !== -1 && (
                  <div className="card-v3 mb-4">
                    <div className="card-body">
                      <div
                        className="sub-header-line mb-2"
                        style={{ fontWeight: "400" }}
                      >
                        Recent Batch Activity
                        {userPermissions.indexOf("Batch") !== -1 && (
                          <Link
                            to={
                              "/" +
                              PayabliStorageManager.getEntryName() +
                              "/report/batches/out"
                            }
                            className="link-default float-end"
                          >
                            View Report
                          </Link>
                        )}
                      </div>
                      <table className="table small">
                        <tbody>
                          {this.state.recentBatchMo.map((record, i) => (
                            <tr key={i}>
                              <td>
                                <BiTrendingUp style={{ fontSize: "15px" }} />
                              </td>
                              <td>
                                <b>
                                  {this.props.global.stringDateFormat(
                                    record.BatchDate
                                  )}
                                </b>{" "}
                                <br />
                                <span className="grey small-small">date</span>
                              </td>
                              <td className="text-center">
                                {this.props.global.getBatchMoStatus(
                                  record.BatchStatus
                                )}{" "}
                                <br />
                                <span className="grey small-small">status</span>
                              </td>
                              <td className="text-right">
                                $
                                {this.props.global.numberWithCommas(
                                  record.BatchAmount.toFixed(2)
                                )}{" "}
                                <br />
                                <span className="grey small-small">amount</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {userPermissions.indexOf("MoneyOut Actions") !== -1 && (
                  <div className="card-v3 mb-4">
                    <div className="card-body">
                      <div
                        className="sub-header-line mb-2"
                        style={{ fontWeight: "400" }}
                      >
                        Recent Transactions
                        {userPermissions.indexOf("MoneyOut Actions") !== -1 && (
                          <Link
                            to={
                              "/" +
                              PayabliStorageManager.getEntryName() +
                              "/report/transactions/out"
                            }
                            className="link-default float-end"
                          >
                            View Report
                          </Link>
                        )}
                      </div>
                      <table className="table small">
                        <tbody>
                          {this.state.accountTransactionsMo.map((record, i) => (
                            <tr key={i}>
                              <td>
                                {this.props.global.stringDateFormat(
                                  record.LastUpdated
                                )}
                                ,{" "}
                                {this.props.global.stringTimeFormat(
                                  record.LastUpdated
                                )}{" "}
                                {"(" +
                                  this.props.global.getTimeZone("v1") +
                                  ")"}
                              </td>
                              <td className="text-center">
                                $
                                {this.props.global.numberWithCommas(
                                  record.TotalAmount.toFixed(2)
                                )}{" "}
                                <br />
                                <span className="grey small-small">amount</span>
                              </td>
                              <td className="text-right">
                                {record.Payor && record.Payor.FirstName
                                  ? record.Payor.FirstName
                                  : ""}{" "}
                                {record.Payor && record.Payor.LastName
                                  ? record.Payor.LastName
                                  : ""}{" "}
                                <br />
                                <span className="grey small-small">pay</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ToastContainer transition={Bounce} />
        </div>
      </Layout>
    );
  }
}

export { Dashboard };
