import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer , toast, Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '../../components/Layout';
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager'
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { OrganizationsLinks } from '../../components/OrganizationsLinks';
import { RightPanel } from '../../components/RightPanel';
import { ReportPaginator } from '../../components/ReportPaginator';
import { OrganizationOverviewLinks } from '../../components/OrganizationOverviewLinks';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { SessionManager } from '../../api/sessionManager';
import { MainBar } from '../../components/MainBar';
import { BiDotsVerticalRounded, BiMinusCircle, BiCheckCircle} from 'react-icons/bi';
import * as Sentry from "@sentry/react";

@inject('reports', 'global', 'entry')
@observer
class PaypointsReport extends React.Component {

    constructor(props) {
        super(props);
        this.clearFilters = this.clearFilters.bind(this);
        this.state = {
            isRoot:false,
            flagItemAllColumn: true,
            currentOrgName: '',
            bankDataColumns : [],
            bankDataColumnsDepositCount: 0,
            bankDataColumnsWithdrawCount: 0
        };
        this.showHideFilters = this.showHideFilters.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.renderStatus = this.renderStatus.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.getBankingInfoColumns = this.getBankingInfoColumns.bind(this);
        this.getHeaders = this.getHeaders.bind(this);
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let iroot = encryptStorage && encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry') && encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').isRoot ? 
            encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').isRoot : false;
        this.setState({
            idOrg: this.props.match.params.idOrg ? this.props.match.params.idOrg : encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId, isRoot:iroot
        });

        if(this.props.match.params.idOrg){
            this.props.entry.getOrganization(this.props.match.params.idOrg).then(res => {
                this.setState({currentOrgName:res.OrgName});
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
        
        this.props.reports.setFrom(0);
        this.props.global.setRightPanelOpen(false);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(this.getHeaders());
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

	componentDidUpdate(prevProps) {
		if (prevProps.reports.getBankingInfoCol) {
			this.getBankingInfoColumns(false)
		}
	}

    getHeaders(){
        return {
            OrgParentName   : { label:'Parent Organization', class: '', display: true },
            OrgId           : { label:'Organization ID', class: '', display: false },
            LegalName       : { label:'Paypoint', class: '', display: true },
            PointId         : { label:'Paypoint ID', class: '', display: true },
            PaypointId      : { label:'External Paypoint ID', class: '', display: true },
            EntryName       : { label:'Entrypoint', class: '', display: true},
            DbaName         : { label:'DBA Name', class: '', display: true},
            MAddress        : { label:'Address', class: '', display: true},
            MCity           : { label:'City', class: '', display: true},
            MState          : { label:'State', class: '', display: true},
            MZip            : { label:'ZipCode', class: '', display: true},
            ContactData     : { label:'Contact', class: '', display: true},
            PaypointStatus  : { label:'Status', class: '', display: true},
            CreatedAt       : { label:'Created', class: '', display: true},
            countSubs       : { label:'Subscriptions', class: 'text-right', display: true},
            countTx         : { label:'Transactions', class: 'text-right', display: true},
            amountTx        : { label:'Volume', class: 'text-right', display: true},
        }
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    clearFilters(){
        this.setState({ head: "" }, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format) {
        this.props.reports.exportAllDataFile('paypoints', format, this.state.idOrg);
    }

    renderStatus(record){
        let status = -1
        if(record && record.PaypointStatus >= 0){
            status = record.PaypointStatus
        }
        switch(status){
            case 0:
                return <span className="badge bg-light"><BiMinusCircle /> Inactive</span>;
            case 1:
                return <span className="badge bg-primary"><BiCheckCircle /> Active</span>;
            default:
                return '-'
        }
    }

    redirectSso(pEntry){
        let objThis = this;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let user = encryptStorage && encryptStorage.getItem('pUser') ? encryptStorage.getItem('pUser') : null;
        let entryLogo = null;

        if(user && user.id && pEntry.EntryName){
            this.props.global.setLoading(true);

            this.props.entry.getEntryFromApi(pEntry.EntryName).then(res => {
                entryLogo = res?.responseData?.EntryLogo;
                if(entryLogo === null){
                    entryLogo = PayabliCookieManager.readCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`) || "payabli.png";
                }
                setTimeout(function(){
                    SessionManager.getNewToken().then(res => {
                        if(res){
                            PayabliCookieManager.createCookie(pEntry.EntryName+'_pToken',res,1);
                            PayabliCookieManager.createCookie(`${pEntry.EntryName}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`, objThis.props.global.normalizeFileUrl(entryLogo), 1);
    
                            window.open(`${process.env.REACT_APP_URL_PAYPOINT}${pEntry.EntryName}/sso`,'_blank');
            
                        }else{
                            let errorMessage = "Something is Wrong. Can't open a Paypoint.";
                            toast.error(errorMessage, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: 'toast-error-container',
                            });
                        }
                        objThis.props.global.setLoading(false);
                    })
                    .catch(error => {
                        objThis.props.global.setLoading(false);
                        toast.error("Something is Wrong. "+ error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                }, 500)
            })
            .catch(error => {
                Sentry.captureException(error);
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
                return;
            });
            
        }
    }
    redirectOrg(pEntry){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        PayabliCookieManager.createCookie('pToken',encryptStorage.getItem('pToken'),1);
        window.open(`${process.env.REACT_APP_URL_PAYPOINT}${pEntry.EntryName}/sso`,'_blank');
    }

    viewRecordDetails(index, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            let thisObj = this;
            if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                thisObj.props.global.setRightPanelOpen(false);
                thisObj.props.reports.setRecordDetails(index);
                setTimeout(function(){
                    thisObj.props.global.setRightPanelOpen(true);
                }, 500)
            }else{
                thisObj.props.reports.setRecordDetails(index);
                thisObj.props.global.setRightPanelOpen(true);
            }
        }
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filterOrg(type,value, 'paypoints').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    getReportFromApi() {
        this.props.global.setLoading(true);
        this.props.reports.getReportOrgFromApi('paypoints',this.state.idOrg ? this.state.idOrg : null).then((res) => {
            this.props.global.setLoading(false);
            this.getBankingInfoColumns(true);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    newPaypoint(e) {
        this.props.history.push("/"+this.props.global.getURLEntry()+ "/management/paypoints/" + this.state.idOrg + "/0");
    }

    getBankingInfoColumns(updateHeaders){
        let records = this.props.reports.records;
		this.props.reports.setBankingInfoCol(false)

        let columns = {
            deposit: 0,
            withdraw: 0,
        };
        let dataColumns = [];

        records.forEach((item, i) => {
            let dataRecords = [];
            let dataRecordsDeposits = [];
            let dataRecordsWithdraw = [];

            if(item.BankData.length){
                item.BankData.forEach((data) => {
                    if(data.bankAccountFunction === 0){
                        //Deposit
                        dataRecordsDeposits.push(data);
                    }else if(data.bankAccountFunction === 1){
                        //Withdraw
                        dataRecordsWithdraw.push(data);
                    }else if(data.bankAccountFunction === 2){
                        //Both
                        dataRecordsDeposits.push(data);
                        dataRecordsWithdraw.push(data);
                    }
                })

            }

            columns.deposit = columns.deposit < dataRecordsDeposits.length ? dataRecordsDeposits.length : columns.deposit;
            columns.withdraw = columns.withdraw < dataRecordsWithdraw.length ? dataRecordsWithdraw.length : columns.withdraw;

            dataRecords.deposit = dataRecordsDeposits;
            dataRecords.withdraw = dataRecordsWithdraw;
            dataColumns[i]= dataRecords;
        });

        let reportHeaders = this.getHeaders();

        for(let d=0;d < columns.deposit ;d++){
            reportHeaders[`deposit${d}`] = { label:`Deposit Account ${d ? d:''}`, class: '', display: false }
        }
        for(let w=0;w < columns.withdraw ;w++){
            reportHeaders[`withdraw${w}`] = { label:`Withdraw Account ${w ? w:''}`, class: '', display: false }
        }

        if(updateHeaders === true){
            this.props.reports.setHeaders(reportHeaders);
        }

        this.setState({
            bankDataColumns : dataColumns,
            bankDataColumnsDepositCount : columns.deposit > this.state.bankDataColumnsDepositCount ? columns.deposit: this.state.bankDataColumnsDepositCount,
            bankDataColumnsWithdrawCount : columns.withdraw > this.state.bankDataColumnsWithdrawCount ? columns.withdraw: this.state.bankDataColumnsWithdrawCount
        })
    }

    renderDataDepositWithdraw(count, dataArray, type){

        let htmltags = [];
        for (let i = 0; i < count; i++) {
            if(this.props.reports.headers[`${type}${i}`] && this.props.reports.headers[`${type}${i}`].display){
                if(dataArray.length > 0 && dataArray[i]){
                    htmltags.push(<td key={i} className='text-left small-tiny'>Routing: {dataArray[i].routingAccount}<br/>Account: {dataArray[i].accountNumber}</td>)
                }else{
                    htmltags.push(<td key={i} className='text-left small-tiny'>-</td>)
                }
            }
        }
        return htmltags;
    }

    render() {
        const recordDetail = this.props.reports.getRecordDetails;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];

        return (
            <Layout {...this.props}>

            <RightPanel>
                <h5 className="header mb-3" data-qaid="paypointQuickView">Paypoint Quick View</h5>
               
               <div className="small mb-5">
                    <div className="row mb-4">
                       <div className="col"><label className="header">Logo</label></div>
                       <div className="col">{ recordDetail && recordDetail.EntryPoints && recordDetail.EntryPoints[0] && recordDetail.EntryPoints[0].EntryLogo ? 
                        <img alt="" style={{width: "130px"}} src={recordDetail.EntryPoints[0].EntryLogo}></img>
                       : '-' }</div>
                   </div>

                   <div className="row">
                       <div className="col"><label className="header">Paypoint ID</label></div>
                       <div className="col">{ recordDetail ? recordDetail.IdPaypoint : '-' }</div>
                   </div>
                   
                   <div className="row">
                       <div className="col"><label className="header">DBA Name</label></div>
                       <div className="col">{ recordDetail && recordDetail.DbaName ? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.DbaName) : '-' }</div>
                   </div>
                   <div className="row">
                       <div className="col"><label className="header">Legal Name</label></div>
                       <div className="col">{ recordDetail && recordDetail.LegalName ? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.LegalName) : '-' }</div>
                   </div>
                   <div className="row">
                       <div className="col"><label className="header">Entrypoint</label></div>
                       <div className="col">{ recordDetail && recordDetail.EntryPoints && recordDetail.EntryPoints[0] ? recordDetail.EntryPoints[0].EntryName : '-' }</div>
                   </div>
                   
                   <div className="row">
                       <div className="col"><label className="header">Address</label></div>
                       <div className="col">{ recordDetail  && recordDetail.BAddress1 ? recordDetail.BAddress1 + ", " + recordDetail.BAddress2: '-' }</div>
                   </div>
                   { (recordDetail && recordDetail.BAddress1 )  &&
                   <>
                   <div className="row">
                       <div className="col"></div>
                       <div className="col">{ recordDetail.BCity }, { recordDetail.BState } { recordDetail.BZip }</div>
                   </div>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col">{ recordDetail.BCountry }</div>
                    </div>
                    </>
                    }
                    <div className="row">
                       <div className="col"><label className="header">Time Zone</label></div>
                       <div className="col">{ "UTC" }</div>
                    </div>
                    <div className="row">
                       <div className="col"><label className="header">Date Created {"("+this.props.global.getTimeZone('v1')+")"}</label></div>
                       <div className="col">{ recordDetail && recordDetail.CreatedAt ? this.props.global.stringDateFormat(recordDetail.CreatedAt)  + ", " + this.props.global.stringTimeFormat(recordDetail.CreatedAt) : '-' }</div>
                   </div>
                    <div className="row mb-4">
                       <div className="col"><label className="header">Status</label></div>
                       <div className="col">{ this.renderStatus(recordDetail)}</div>
                    </div>

                   <h5 className="header mb-3">Contact Information</h5>
                   { (recordDetail && recordDetail.ContactData )  &&                    
                        recordDetail.ContactData.map((record, i) => (
                            <div key={i} className="mb-3">
                            <div className="row">
                                <div className="col"><label className="header">Paypoint Contact</label></div>
                                <div className="col">{record.ContactName ? record.ContactName : "-"}</div>
                            </div>
                            <div className="row">
                                <div className="col"><label className="header">Title</label></div>
                                <div className="col">{record.ContactTitle ? record.ContactTitle : "-"}</div>
                            </div>
                            <div className="row">
                                <div className="col"><label className="header">Phone</label></div>
                                <div className="col">{record.ContactPhone ? record.ContactPhone : "-"}</div>
                            </div>
                            <div className="row mb-4">
                                <div className="col"><label className="header">Email</label></div>
                                <div className="col">{record.ContactEmail ? record.ContactEmail : "-"}</div>
                            </div>
                            </div>
                        ))
                    }

                    { (recordDetail && recordDetail.BankData && recordDetail.BankData.length > 0)  &&   
                    <>
                    <h5 className="header mb-3">Banking Information</h5>
                    {(recordDetail && recordDetail.BankData )  &&                    
                        recordDetail.BankData.map((record, i) => (
                            <div key={i} className="mb-3">
                            <div className="row">
                                <div className="col"><label className="header">Bank Name</label></div>
                                <div className="col">{record.bankName ? record.bankName : "-"}</div>
                            </div>
                            <div className="row">
                                <div className="col"><label className="header">Routing Number</label></div>
                                <div className="col">{record.routingAccount ? record.routingAccount : "-"}</div>
                            </div>
                            <div className="row">
                                <div className="col"><label className="header">Account Number</label></div>
                                <div className="col">{record.accountNumber ? record.accountNumber : "-"}</div>
                            </div>
                            <div className="row">
                                <div className="col"><label className="header">Account Type</label></div>
                                <div className="col">{record.typeAccount ? record.typeAccount : "-"}</div>
                            </div>
                            <div className="row">
                                <div className="col"><label className="header">Account Function</label></div>
                                <div className="col">{this.props.global.getBankAccountFunction(record.bankAccountFunction)}</div>
                            </div>
                            </div>
                        ))
                        }
                    </>
                    }
            </div>
            </RightPanel>
            <div>
                <ParentFilterPanel report={'paypoints'} orgId={this.props.match.params.idOrg} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    { this.props.match.params.idOrg ? 
                    <OrganizationOverviewLinks goback={true} gobackLink={'/'+this.props.global.getURLEntry() + '/orgdetails/'+this.state.idOrg} gobackText="Org. Overview" idOrg={this.state.idOrg} selected="paypoints"/>
                    :
                    <OrganizationsLinks selected="paypoints"/>
                    }
                </div>
                </TopBar>                
                <div className="mt-body4">
                    <MainBar
                        btnNewName="New Paypoint" 
                        reportName="Paypoints"
                        dataQAName="PayPoints"
                        newOnClick={(e) => this.newPaypoint(e)}
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        newButton={this.state.isRoot && userPermissions.indexOf("Management") !== -1}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        masterName={this.props.match.params.idOrg && `Organization - ${this.state.currentOrgName}`}
                        withTotal={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 
                <div className="report-container">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                        {
                            this.props.reports.getHeaders.map((record, i) => (
                                ( record[1].display && <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                            ))
                        }

                        <th className='text-center sticky-row' scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>                
                { this.props.reports.records.map((record, i) => (
                        <tr  key={i} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} className="cursorPointer" onClick={(e) => this.viewRecordDetails(i,e)} data-qaid={"paypointRow-"+(i)}>                            
                            { (this.props.reports.headers.OrgParentName && this.props.reports.headers.OrgParentName.display) &&
                                <td>{record && record.OrgParentName ? record.OrgParentName: '-'}</td>
                            }
                            { (this.props.reports.headers.OrgId && this.props.reports.headers.OrgId.display) &&
                                <td>{record && record.OrgId ? record.OrgId : '-'}</td>
                            }
                            { (this.props.reports.headers.LegalName && this.props.reports.headers.LegalName.display) &&
                                <td>{record && record.LegalName ? this.props.global.capitalizeFirstLetterOfEachWord(record.LegalName): '-'}</td>
                            }
                            { (this.props.reports.headers.PointId && this.props.reports.headers.PointId.display) &&
                                <td>{record && record.IdPaypoint ? record.IdPaypoint : '-'}</td>
                            }
                            { (this.props.reports.headers.PaypointId && this.props.reports.headers.PaypointId.display) &&
                                <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>
                            }
                            { (this.props.reports.headers.EntryName && this.props.reports.headers.EntryName.display) &&
                                <td>{record && record.EntryPoints && record.EntryPoints[0] ? record.EntryPoints[0].EntryName : '-'}</td>
                            }
                            { (this.props.reports.headers.DbaName && this.props.reports.headers.DbaName.display) &&
                                <td>{record && record.DbaName ? record.DbaName : '-'}</td>
                            }
                            { (this.props.reports.headers.MAddress && this.props.reports.headers.MAddress.display) &&
                                <td>{record && record.BAddress1 ? record.BAddress1: '-'}</td>
                            }
                            { (this.props.reports.headers.MCity && this.props.reports.headers.MCity.display) &&
                                <td>{record && record.BCity ? record.BCity: '-'}</td>
                            }
                            { (this.props.reports.headers.MState && this.props.reports.headers.MState.display) &&
                                <td>{record && record.BState ? record.BState: '-'}</td>
                            }
                            { (this.props.reports.headers.MZip && this.props.reports.headers.MZip.display) &&
                                <td>{record && record.BZip ? record.BZip: '-'}</td>
                            }                            
                            { (this.props.reports.headers.ContactData && this.props.reports.headers.ContactData.display) &&
                                <td>{record && record.ContactData && record.ContactData[0] && record.ContactData[0].ContactName && record.ContactData[0].ContactName !== '' ? record.ContactData[0].ContactName : '-'}</td>
                            }
                            { (this.props.reports.headers.PaypointStatus && this.props.reports.headers.PaypointStatus.display) &&
                                <td>{this.renderStatus(record)}</td>
                            }
                            { (this.props.reports.headers.CreatedAt && this.props.reports.headers.CreatedAt.display) &&
                                <td>{record && record.CreatedAt ? this.props.global.stringDateFormatV3(record.CreatedAt) : '-'}</td>
                            }
                            { (this.props.reports.headers.countSubs && this.props.reports.headers.countSubs.display) &&
                                <td className='text-right'>{record.summary  ? record.summary.countSubs: '-'}</td>
                            }
                            { (this.props.reports.headers.countTx && this.props.reports.headers.countTx.display) &&
                                <td className='text-right'>{record.summary  ? record.summary.countTx: '-'}</td>
                            }
                            { (this.props.reports.headers.amountTx && this.props.reports.headers.amountTx.display) &&
                                <td className='text-right'>{record.summary  ? "$" + this.props.global.numberWithCommas(parseFloat(record.summary.amountTx).toFixed(2)) : '-'}</td>
                            }
                        

                            {
                                this.state.bankDataColumns[i] &&
                                (
                                    this.renderDataDepositWithdraw(this.state.bankDataColumnsDepositCount, this.state.bankDataColumns[i]['deposit'], 'deposit')
                                )
                            }

                            {
                                this.state.bankDataColumns[i] &&
                                (
                                    this.renderDataDepositWithdraw(this.state.bankDataColumnsWithdrawCount, this.state.bankDataColumns[i]['withdraw'], 'withdraw')

                                )
                            }

                            <td className='text-center sticky-row'>
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    data-qaid={"actionPoint-"+(record && record.LegalName)}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                    >
                                    <Dropdown.Item onClick={(e) => this.viewRecordDetails(i)} data-qaid="actionPaypointsQuickViewLink"><div>Quick View</div></Dropdown.Item>                                
                                    { (record && record.EntryPoints && record.EntryPoints[0]) &&
                                        <Dropdown.Item as={Link} to={"/"+this.props.global.getURLEntry()+"/paypoints/overview/"+record.EntryPoints[0].EntryName+"/"+ record.IdPaypoint} data-qaid="actionViewDetailsLink">View Details</Dropdown.Item>
                                    }
                                    {(record && this.state.isRoot) &&
                                        <Dropdown.Item as={Link} to={{ pathname: "/"+PayabliStorageManager.getEntryName()+"/management/paypoints/" + this.state.idOrg + "/" + record.IdPaypoint, state: { entryname: record && record.EntryPoints && record.EntryPoints[0] && record.EntryPoints[0].EntryName ? record.EntryPoints[0].EntryName : '' } }} data-qaid="actionPaypointsManageLink">Manage Paypoint</Dropdown.Item>
                                    }
                                    { (record && record.EntryPoints && record.EntryPoints[0] && (record.PaypointStatus === 1)) &&
                                        <Dropdown.Item  onClick={() => this.redirectSso(record.EntryPoints[0])} rel="noreferrer" data-qaid="actionGoToPayPointLink">Go to Paypoint</Dropdown.Item>
                                    }
                                    {/* { (record && record.EntryPoints && record.EntryPoints[0] && (record.PaypointStatus === 1)) &&
                                        <Dropdown.Item as={Link} target="_blank" to={"/orgdetails/"+this.state.idOrg}>Go to Organization</Dropdown.Item>
                                    } */}
                                </DropdownButton>
                            </td>                           
                        </tr>
                    ))
                }                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No paypoints just yet" description={<span>Paypoints are the equivalent of merchants. <br/>As soon as you start onboarding paypoints, you can manage them here.</span>}/>
                }
                </div>
                <ReportPaginator mode={this.state.idOrg} report="paypoints" option={1} callBackFunction={this.getBankingInfoColumns}/>
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { PaypointsReport };