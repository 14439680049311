import React from 'react'
import RowContainer from '../../../../../components/commandCenter/RowContainer'
import GlobalStore from '../../../../../store/GlobalStore'

export default function CheckCommerce({ inputValues, SetInputValues, setClassValidate, classValidate }) {
	return (
		<>
			{inputValues.processor === 'checkcommerce' && (
				<div className="d-flex flex-column">
					<div className="d-flex flex-row justify-content-between align-items-start flex-wrap p-0">
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.checkcommerce.username}
								inputDisabled={false}
								labelValue="User Name"
								name="username"
								placeholder="Value"
								value={inputValues.username}
								onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, username: inputValues.username ? '' : 'is-invalid' } })}
								setValue={(values) => {
									const newValue = GlobalStore.removeSpaces(values)
									SetInputValues({ ...inputValues, username: newValue })
									setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, username: newValue ? '' : 'is-invalid' } })
								}}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.checkcommerce.password}
								inputDisabled={false}
								labelValue="Password"
								name="password"
								placeholder="Value"
								value={inputValues.password}
								onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, password: inputValues.password ? '' : 'is-invalid' } })}
								setValue={(values) => {
									const newValue = GlobalStore.removeSpaces(values)
									SetInputValues({ ...inputValues, password: newValue })
									setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, password: newValue ? '' : 'is-invalid' } })
								}}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>
						<div className="d-flex flex-column align-items-start p-0">
							<RowContainer
								inputType="mask"
								classNameInput={classValidate.checkcommerce.merchantNumber}
								inputDisabled={false}
								labelValue="Merchant Number"
								name="merchantNumber"
								placeholder="Value"
								value={inputValues.merchantNumber}
								onBlur={() => setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, merchantNumber: inputValues.merchantNumber ? '' : 'is-invalid' } })}
								setValue={(values) => {
									const newValue = GlobalStore.removeSpaces(values)
									SetInputValues({ ...inputValues, merchantNumber: newValue })
									setClassValidate({ ...classValidate, checkcommerce: { ...classValidate.checkcommerce, merchantNumber: newValue ? '' : 'is-invalid' } })
								}}
								mask={GlobalStore.maskValidator('text')}
								maxLength={50}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
